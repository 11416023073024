/**
 *  @file       api/debugging.js
 *  @author     Eddie Roosenmaallen <eddie@kingsds.network>
 *  @author     Joash Mathew <joash@distributive.network>
 *  @date       June 2022
 *  @date       July 2023
 *
 *  This module exports a debugging() interface from DCP's debugging module,
 *  which can be extended as needed in different areas of the code.
 */

export const debugging = window.dcp.debugging.scope('portal');

