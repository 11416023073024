/**
 * Formats a bank account address to be the last 6 characters with an ellipses at the beginning
 * 
 * @param {string} address bank account address
 * @returns {string}       formatted bank account address
 */
export function formatBankAccountAddress(address) {
  return `${String(new window.dcp.wallet.Address(address)).toLowerCase().slice(0, 8)}…`;
}

/**
 * Formats a bank account label with ellipsis if it exceeds a specific number of characters
 * 
 * @param {string} label account label
 * @returns              formatted bank account label
 */
export function formatAccountLabel(label) {
  const labelTruncationLength = 13;

  let formattedLabel = label.substring(0, labelTruncationLength);
  if (label.length > labelTruncationLength)
    formattedLabel += '…';
  return formattedLabel;
}
