/**
 * @file    BreadCrumb.jsx
 * 
 *          A generic bread crumb component that allows users to navigate back to previous pages.
 * 
 * @author  Kevin Yu <kevin@distributive.network>
 * @date    June 2024
 */

import { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import './BreadCrumb.css';

/**
 * Displays breadcrumb link when in a deeply nested route
 *
 * @param  {object}      props        Component properties.
 * @param  {object[]}    props.routes The routes to render.
 * @return {JSX.Element}              The rendered element.
 */
export function BreadCrumb({ routes })
{

  /**
   * Prevents the last breadcrumb link from being clickable
   *
   * @param {object} event onClick event
   * @param {number} i     the index for the route 
   */
  const handleDisableLastLink = (event, i) => {
    if (i === routes.length - 1)
      event.preventDefault();
  };

  return (
    <div className="bread-crumb">
      {routes.map((route, idx) => (
        <Fragment key={route.href}>
          <NavLink
            to={route.href}
            tabIndex={idx === routes.length - 1 ? -1 : 0}
            onClick={(e) => handleDisableLastLink(e, idx)}
          >
            {route.label}
          </NavLink>
          <span>/</span>
        </Fragment>
      ))}
    </div>
  );
}
