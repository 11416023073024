/**
 *  @file       operations/erroroperations.js
 *  @author     Alex Huctwith <alex@kingsds.network>
 *              David Courtis <david@distributive.network>
 *  @date       April 2022
 *              Feb. 2024
 *
 *              Portal client operations related to displaying errors
 */

/**
 * Display an error to the user
 * @param {string} error text to display to the user
 */
export class ErrorOperations {
  static displayError(error) {
    alert(error);
  }

  /**
   * Display a box prompting the user with a question related to the error
   * @param {string} error text to display to the user
   * @returns {boolean} true if the user answered yes, false if they answered no
   */
  static displayErrorQuestion(error) {
    return confirm(error);
  }
}
