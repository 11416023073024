/**
 * @file    PatchNotesModal.jsx - Exports a function that renders a modal component. Reads markdown
 *          files from src/patch-notes and converts them to JSX. Then renders them in this
 *          component.
 * @author  Jordan Cartlidge <jordan@kingsds.network>
 * @author  Arnab Sagar <arnab@kingsds.network>
 * @author  Bryan Hoang <bryan@distributive.network>
 * @date    June 2022, July 2023
 */
import { useEffect, useState, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import ReactPaginate from 'react-paginate';

import { Modal } from '@/components/Elements';
import { Button } from '@/components/Elements';

import './PatchNotesModal.css';

const { VITE_ASSETS_PATH } = import.meta.env;

// svg for forward and back arrows pages
const previousLabel = (
  <svg
    className="paginateArrow"
    width="15"
    height="27"
    viewBox="0 0 15 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M13 2L3 13.5L13 25" strokeWidth="4" />
  </svg>
);

const nextLabel = (
  <svg
    className="paginateArrow"
    width="15"
    height="27"
    viewBox="0 0 15 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M2 25L12 13.5L2 2" strokeWidth="4" />
  </svg>
);

const itemsPerPage = 1;

/**
 * Create sorted data structure to lazily load patch note markdown text whenever the user tries to
 * load a new patch note.
 */
const patchNoteModules = import.meta.glob('../../../patch-notes/*.md', {
  // Import as strings for `react-markdown` to ingest.
  query: '?raw',
  import: 'default',
  // Statically import to prevent splitting patch notes into multiple tiny chunks.
  eager: true,
});
const patchNotesFilePaths = sortPatchNotes(Object.keys(patchNoteModules));

/**
 * @param {string[]} unsortedPatchNotes
 * @returns {string[]}
 */
function sortPatchNotes(unsortedPatchNotes)
{
  const patchNotePaths = unsortedPatchNotes
    .map((path) => path.replace(/\d+/g, (n) => Number(n) + 10000))  // pad version numbers so they can be sorted
    .sort()
    .reverse()
    .map((path) => path.replace(/\d+/g, (n) => Number(n) - 10000)); // unpad them

  return patchNotePaths;
}

/**
 * Renders the patch notes modal.
 * @param   {object}      props            Component properties.
 * @param   {boolean}     props.isOpen     If the modal is open.
 * @param   {function}    props.closeModal Callback to close the modal.
 * @returns {JSX.Element}                  The rendered modal element.
 */
export function PatchNotesModal({ isOpen, closeModal })
{
  const [patchNotes, setPatchNotes] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const patchNoteRef = useRef(null);
  
  // Get the patch note(s) to render based on the currently offset.
  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    const patches = [];
    for (const path of patchNotesFilePaths.slice(itemOffset, endOffset))
    {
      const patchNoteText = patchNoteModules[path];
      patches.push(patchNoteText);
    }

    setPatchNotes(patches);
    setPageCount(Math.ceil(patchNotesFilePaths.length / itemsPerPage))
  }, [itemOffset]);

  function handlePageClick(event)
  {
    // Calculating offset starting point for current selected page.
    const newOffset = (event.selected * itemsPerPage) % patchNotesFilePaths.length;
    setItemOffset(newOffset);
  }

  function resetAndCloseModal()
  {
    closeModal();
  }
  
  const modalStyle = {
    overflow: 'none',
    WebkitOverflowScrolling: 'touch',
    width: '800px',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '25px',
    margin: '0',
    maxWidth: '100%',
    boxShadow: '-4px 4px 4px rgba(0, 0, 0, 0.4)',
  };
  
  function onScroll()
  {
    if (patchNoteRef.current) 
    {
      const { scrollTop, scrollHeight, clientHeight } = patchNoteRef.current;
      if (scrollTop + clientHeight === scrollHeight) 
      {
        patchNoteRef.current.style.boxShadow = 'none';
      }
      else
      {
        patchNoteRef.current.style.boxShadow = 'inset 0 -1.5em 1em -1em rgba(0,0,0,0.4)';
      }
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      closeModal={resetAndCloseModal}
      heading=""
      hideHeader={true}
      contentStyle={modalStyle}
      id="patchNotesModal"
    >
      <img src={`${VITE_ASSETS_PATH}/img/company-banner.png`} alt="DCP"></img>
      <div className="patchNotesContent" onScroll={onScroll} ref={patchNoteRef}>
        <div className="patchNotesAll">
          <h2>Patch Notes</h2>
          {patchNotes.map((patchNote, _index) => {
            return <ReactMarkdown key={patchNote} className="patchNote" children={patchNote}/>;
          })}
        </div>
      </div>
      <div className="patchNotesNav">
        <ReactPaginate
          breakLabel="..."
          previousLabel={previousLabel}
          nextLabel={nextLabel}
          nextClassName="nextPrevButton page-item"
          previousClassName="nextPrevButton page-item"
          onPageChange={handlePageClick}
          pageRangeDisplayed={1}
          pageCount={pageCount}
          renderOnZeroPageCount={null}
          pageClassName="page-item"
          breakClassName="page-item"
          containerClassName="pagination"
          activeClassName="active"
        />
        <Button onClick={closeModal} primary="" text="Close"></Button>
      </div>
    </Modal>
  );
}
