/**
 * @file    useWorkerPaymentAccount.js - A custom hook that handles getting and
 *          setting the worker payment account for the user.
 *
 * @author  Bryan Hoang <bryan@distributive.network>
 * @date    Aug. 2024
 */

import { useCallback, useContext, useEffect } from 'react';

import { AccountsContext } from '@/features/accounts';
import { useLocalStorage } from '@/hooks/useLocalStorage';

/**
 * Returns the worker payment account user preference along with a setter.
 *
 * The custom hook handles cases when a previously save payment account is
 * invalid/not found for the current user.
 *
 * @params {object} initialAccount - The initial account to use for the preference.
 * @returns {Array}
 */
export function useWorkerPaymentAccount(initialAccount)
{
  // TODO(bryan-hoang): Replace local storage implementation with server-side
  // storage prefs once implemented in DCP-4306.
  const [localPrefs, setLocalPrefs] = useLocalStorage(sessionStorage.getItem('identity'), {
    workerPaymentAccount: initialAccount,
  });
  const accounts = useContext(AccountsContext);

  const setWorkerPaymentAccount = useCallback((workerPaymentAccount) => {
    setLocalPrefs((previousLocalPrefs) => {
      previousLocalPrefs.workerPaymentAccount = workerPaymentAccount;
      return previousLocalPrefs;
    });
  }, [setLocalPrefs])

  // If there's an invalid payment account saved, defer to the
  // default behaviour of selecting the first account. Can occur when a
  // previously saved account was deleted, or a dev ran `build-dev-platform` and
  // is using the same account for their sign in.
  useEffect(() => {
    if (
      localPrefs.workerPaymentAccount
      && !accounts.find((account) => account.dbKey === localPrefs.workerPaymentAccount.dbKey)
    )
    {
      setWorkerPaymentAccount(accounts[0]);
    }
  }, [accounts, localPrefs.workerPaymentAccount, setWorkerPaymentAccount])

  return [localPrefs.workerPaymentAccount, setWorkerPaymentAccount];
}
