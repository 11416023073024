/**
 *  @file       TermsOfServicePage.jsx
 *  @author     Robert Mirandola <robert@distributive.network>
 *
 *  @date       November 2022
 *
 *              Portal page for displaying the terms of service
 */

import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { Layout } from '@/components/Layout';
import { Heading } from '@/components/Elements/Heading';

export function TermsOfServicePage(props) {
  const { t } = useTranslation();
  return(
    <Layout contentClassName="termsOfService">
      <Heading heading={t('tos')}/>
      <p>{t('tos_par_1')}</p>
      <p>{t('tos_par_2')}</p>
      <p>{t('tos_par_3')}</p>
      <p>
      {t('tos_par_4_part_1')}
      <a href="mailto:info@distributive.network">info@distributive.network</a>.
      {t('tos_par_4_part_2')}
      </p>
      <h2>
      <span className="bolded">{t('tos_privacy_policy')}</span>
    </h2>
    <p>
      {t('tos_par_5_part_1')}
      <NavLink to='/policy'>
        <strong>{t('privacy_policy')}</strong>
      </NavLink>
      {t('tos_par_6_part_2')}
    </p>
    <h2>
      <span className="bolded">{t('tos_purchases')}</span>
    </h2>
    <p>{t('tos_par_7')}</p>
    <p>{t('tos_par_8')}</p>
    <p>{t('tos_par_9')}</p>
    <p>{t('tos_par_10')}</p>
    <p>{t('tos_par_11')}</p>
    <h2>
      <span className="bolded">{t('tos_restrictions')}</span>
    </h2>
    <p>{t('tos_par_12')}</p>
    <ol type='i'>
      <li>{t('tos_restrictions_part_1')}</li>
      <li>{t('tos_restrictions_part_2')}</li>
      <li>{t('tos_restrictions_part_3')}</li>
      <li>{t('tos_restrictions_part_4')}</li>
      <li>{t('tos_restrictions_part_5')}</li>
      <li>{t('tos_restrictions_part_6')}</li>
      <li>{t('tos_restrictions_part_7')}</li>
      <li>{t('tos_restrictions_part_8')}</li>
      <li>{t('tos_restrictions_part_9')}</li>
    </ol>
    <p>{t('tos_par_13')}</p>
    <p>
      {t('tos_par_14')}
      <a href="/tos#termination"><span className="bolded">{t('termination')}</span>.</a>
    </p>
    <h2>
      <span className="bolded">{t('tos_compatible_devices')}</span>
    </h2>
    <p>{t('tos_par_15')}</p>
    <h2>
      <span className="bolded">{t('tos_availability')}</span>
    </h2>
    <p>{t('tos_par_16')}</p>
    <p>{t('tos_par_17')}</p>
    <p>{t('tos_par_18')}</p>
    <p>
      {t('tos_par_19_part_1')}
      <a href="mailto:info@distributive.network">info@distributive.network</a>
      {t('tos_par_19_part_2')}
    </p>
    <h2>
      <span className="bolded">{t('tos_accounts')}</span>
    </h2>
    <p>{t('tos_par_20')}</p>
    <p>{t('tos_par_21')}</p>
    <p>
      {t('tos_par_22_part_1')}
      <a href="mailto:info@distributive.network">info@distributive.network</a>
      {t('tos_or')}
      <a href="mailto:privacy@distributive.network">privacy@distributive.network</a>
      {t('tos_par_22_part_2')}
    </p>
    <p>{t('tos_par_23')}</p>
    <h2>
      <span className="bolded">{t('tos_intellectual_property')}</span>
    </h2>
    <p>{t('tos_par_24')}</p>
    <p>{t('tos_par_25')}</p>
    <p>
      {t('tos_par_26')}
      <a href="mailto:info@distributive.network">info@distributive.network</a>.
    </p>
    <h2>
      <span className="bolded">{t('tos_other_websites')}</span>
    </h2>
    <p>{t('tos_par_27')}</p>
    <p>{t('tos_par_28')}</p>
    <h2 id="termination">
      <span className="bolded">{t('termination')}</span>
    </h2>
    <p>{t('tos_par_29')}</p>
    <p>{t('tos_par_30')}</p>
    <p>
      {t('tos_par_30_part_1')}
      <a href="mailto:info@distributive.network">info@distributive.network</a>.
      {t('tos_par_30_part_2')}
    </p>
    <h2>
      <span className="bolded">{t('indemnification')}</span>
    </h2>
    <p>{t('tos_par_31')}</p>
    <p>{t('tos_par_32')}</p>
    <h2>
      <span className="bolded">{t('tos_limitation_of_liability')}</span>
    </h2>
    <p>{t('tos_par_33')}</p>
    <p>{t('tos_par_34')}</p>
    <h2>
      <span className="bolded">{t('tos_disclaimer_of_rights')}</span>
    </h2>
    <p>{t('tos_par_35')}</p>
    <p>{t('tos_par_36')}</p>
    <p>{t('tos_par_37')}</p>
    <p>{t('tos_par_38')}</p>
    <p>{t('tos_par_39')}</p>
    <p>{t('tos_par_40')}</p>
    <h2>
      {t('tos_exclusions')}
    </h2>
    <p>{t('tos_par_41')}</p>
    <h2>
      <span className="bolded">{t('tos_communications')}</span>
    </h2>
    <p>{t('tos_par_42')}</p>
    <p>{t('tos_par_43')}</p>
    <p>{t('tos_par_44')}</p>
    <p>{t('tos_par_45')}</p>
    <h2>
      <span className="bolded">{t('tos_governing_law')}</span>
    </h2>
    <p>{t('tos_par_46')}</p>
    <p>{t('tos_par_47')}</p>
    <h2>
      <span className="bolded">{t('tos_changes')}</span>
    </h2>
    <p>{t('tos_par_48')}</p>
    <p>{t('tos_par_49')}</p>
    <h2>
      <span className="bolded">{t('tos_miscellaneous')}</span>
    </h2>
    <p>{t('tos_par_50')}</p>
    <p>
      {t('tos_par_51_part_1')}
      <NavLink to='/policy'>
        <strong>{t('privacy_policy')}</strong>
      </NavLink>
      {t('tos_par_51_part_2')}
    </p>
    <p>
      {t('tos_par_52_part_1')}
      <a href="mailto:info@distributive.network">info@distributive.network</a>
      {t('tos_par_52_part_2')}
    </p>
    <h2>
      {t('tos_self_reporting')}
    </h2>
    <p>{t('tos_par_53')}</p>
    <p>{t('tos_par_54')}</p>
    <p>
      {t('tos_par_55_part_1')}
      <a href="mailto:info@distributive.network">info@distributive.network</a>.
      {t('tos_par_55_part_2')}
      <NavLink to='/policy'>
        <strong>{t('privacy_policy')}</strong>
      </NavLink>
      {t('tos_par_55_part_3')}
    </p>
    <h2>
      <span className="bolded">{t('tos_contact_us')}</span>
    </h2>
    <p>
      {t('tos_par_56')}
      <a href="mailto:info@distributive.network">info@distributive.network</a>.
    </p>
    </Layout>
  );
}
