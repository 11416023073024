/**
 * @file    useForm.js - Exports a custom hook that wraps the `useForm` hook from `react-hook-form`
 *          that allows us to define default configuration values for the hook that can be
 *          overridden.
 * @author  Bryan Hoang <bryan@kingsds.network>
 * @date    July 2022
 */
import * as ReactHookForm from 'react-hook-form';

const { useForm: useReactHookForm } = ReactHookForm;

/** @typedef {import('react-hook-form').UseFormProps} UseFormProps */
/** @typedef {import('react-hook-form').UseFormReturn} UseFormReturn */
/**
 * @param   {UseFormProps}  props
 * @returns {UseFormReturn}
 */
export function useForm(props) {
  return useReactHookForm({
    // Allow CSS selectors to target `:invalid` pseudoclass.
    shouldUseNativeValidation: true,
    // Don't `onChange` by default.
    reValidateMode: 'onSubmit',
    // Add additional properties or override top-level properties as necessary.
    ...props,
  });
}
