/**
 * @file    routes/index.jsx - Exports a react component representing all the
 *          possible routes the web page can navigate to.
 *
 * @author  Bryan Hoang <bryan@distributive.network>
 * @date    Aug. 2023
 */

import { useRoutes } from 'react-router-dom';

import { protectedRoutes } from './protected';

/**
 * Renders the routes the web app can navigate to.
 *
 * @returns {JSX.Element} The possible routes to navigate to.
 */
export function AppRoutes()
{
  const element = useRoutes([...protectedRoutes]);
  return (
    <>{element}</>
  );
}
