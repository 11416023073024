/**
 * @file    RenameAccountModal.jsx
 *
 *          Exports a function that renders a modal component to rename an account.
 *
 * @author  Bryan Hoang <bryan@kingsds.network>
 * @date    July 2022
 */

import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { useForm } from '@/hooks/useForm';
import { TextInput } from '@/components/Form';
import { Modal } from '@/components/Elements';
import { AccountsContext, AccountsDispatchContext } from '../../stores/AccountsContext';
import { AccountOperations } from '../../api/AccountOperations';

const formId = 'renameAccountForm';
const labelInputName = 'accountName';

/**
 * Renders a modal to rename an account.
 *
 * @param   {object}      props            Component properties.
 * @param   {boolean}     props.isOpen     If the modal is open.
 * @param   {function}    props.closeModal Callback to close the modal.
 * @param   {object}      props.account    The account to rename.
 * @returns {JSX.Element}                  The rendered component.
 */
export function RenameAccountModal({ isOpen, closeModal, account })
{
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const { t } = useTranslation();
  const { handleUpdateAccounts } = useContext(AccountsDispatchContext);
  const accounts = useContext(AccountsContext);

  function resetAndCloseModal() {
    reset();
    closeModal();
  }
  
  /**
   * Validate that the account name is unique.
   *
   * @returns {boolean} The validity of the name.
   */
  function validateUniqueName(newName)
  {
    const currentAccount = account;
    const isUnique = accounts.every((account) => {
      return (
        // Does the new name collide with an existing account name?
        newName !== account.label
        // Otherwise, does the collision occur with just the original account?
        || currentAccount === account
      );
    });

    return isUnique;
  }

  async function saveName(data) {
    account.label = data[labelInputName];
    const updatedAccount = await AccountOperations.updateAccount(account);
    resetAndCloseModal();
    if (updatedAccount)
      handleUpdateAccounts([updatedAccount]);
  }

  return (
    <Modal
      isOpen={isOpen}
      closeModal={resetAndCloseModal}
      heading={t('rename_account')}
      isInvalid={Object.keys(errors).length > 0}
      formId={formId}
      onSubmit={handleSubmit(saveName)}
      defaultLabel={t('confirm')}
      loadingLabel={t('saving')}
    >
      <TextInput 
        label={t('label')}
        defaultValue={account.label}
        {...register(labelInputName, {
          validate: validateUniqueName,
          required: 'A label is required',
        })} 
      />
    </Modal>
  );
}
