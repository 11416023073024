/**
 * @file    FileDropzone.jsx
 *
 *          Exports a component for users to drag and drop a file into a file input.
 *
 * @author  Danny Akbarzadeh <danny@kingsds.network>
 * @author  Bryan Hoang <bryan@kingsds.network>
 * @date    June 2022
 */

import { useDropzone } from 'react-dropzone';

import { useTranslation } from 'react-i18next';

import './FileDropzone.css';

const { VITE_ASSETS_PATH } = import.meta.env;
const inputId = 'fileDropzone';

/**
 * Renders a drag-and-drop area for users to drop files into.
 *
 * The component renders differently based on whether a file has been selected.
 *
 * @param   {object}      props          Component properties.
 * @param   {function}    props.onDrop   Event hander for when a file is dropped into the
 *                                       component.
 * @param   {function}    props.onChange Event handler for when a file is selected.
 * @param   {object}      props.rest     Rest of the props to pass to the dropzone hook.
 * @returns {JSX.Element}                The rendered component.
 */
export function FileDropzone({ onDrop, onChange, ...rest })
{
  const { t } = useTranslation();
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    onDrop: onDrop,
    noKeyboard: true,
    multiple: false,
    ...rest
  });

  // Flag to determine the render state of the component.
  const needsToSelectFile = !Boolean(acceptedFiles.length);

  const className = `dropzone ${needsToSelectFile ? '' : 'fileSelected'}`;
  const inputLabel = needsToSelectFile ? t('drop_file_here') : t('file');

  return (
    <div className={className} {...getRootProps()}>
      <label htmlFor={inputId}>{inputLabel}</label>
      <input id={inputId} {...getInputProps({ onChange })} />
      <img src={`${VITE_ASSETS_PATH}/icons/dropzone-icon.svg`} alt="Dropzone Icon" />
      {!needsToSelectFile && acceptedFiles[0].name}
    </div>
  );
}
