/**
 *  @file       WorkerDebugDialog.jsx
 *  @author     Kirill Kirnichansky <kirill@distributive.network>
 * 
 *  This component allows joining of compute groups and enabling debugging for the
 *  portal worker.
 *
 *  @date       January 2023
 */

import { useState } from 'react';

import utils from '../utils';

import './WorkerDebugDialog.css';

export const WorkerDebugDialog = ({ workerOptions }) => {
  const [val, setVal] = useState(0);

  utils.workerOptions = workerOptions;

  const triggerUpdate = () => { setVal(val + 1); };
  
  const addCg = (form) => {
    utils.addCg(form);
    triggerUpdate();
  };

  const removeCg = (joinKey) => {
    utils.removeCg(joinKey);
    triggerUpdate();
  };

  const onSubmit = (ev) => {
    ev.preventDefault();
    return false;
  }
  
  return (
    <div id="_dbgsrc">
      <form
        autoComplete="off"
        onSubmit={onSubmit}
        onChange={(event) => {
          utils.setPublicGroup(event.currentTarget);
        }}
      >
        <div id="_cgdbginput">
          <label>
            joinKey:
            <input type="text" name="joinKey" />
          </label>
          <br />
          <label>
            joinSecret:
            <input type="text" name="joinSecret" />
          </label>
          <br />
          <button
            type="button"
            onClick={(event) => {
              addCg(event.currentTarget.form);
            }}
          >
            Add
          </button>
          <br />
        </div>
        <div id="_cgdbgoutput">
          <div id="_cgdbgclose" onClick={utils.closePopup}>
            &times;
          </div>
          <strong style={{color: 'black'}}>Joined compute groups:</strong> 
          <br/>
          (click to remove)
          {workerOptions?.computeGroups?.map((group) => {
            return <li key={group.joinKey} onClick={removeCg.bind(null, group.joinKey)}>{group.joinKey}</li>
          })}
        </div>
        Public Group: <input type="radio" name="public" value="yes" defaultChecked />
        &nbsp;yes <input type="radio" name="public" value="no" />
        &nbsp;no
      </form>
    </div>
  );
}
