/**
 * @file    Modal.jsx
 *
 *          Exports a modal layout component used by every modal.
 *
 * @author  Bryan Hoang <bryan@kingsds.network>
 * @date    July 2022
 */

import { useEffect, useRef, useState } from 'react';
import ReactModal from 'react-modal';
import { useTranslation } from 'react-i18next';

import { Button } from '../Button';

import './Modal.css';

const { VITE_ASSETS_PATH } = import.meta.env;

/**
 * Renders a modal layout component used by every modal.
 *
 * @param   {object}              props                    Component props.
 * @param   {React.ReactNode}     props.children           The main content of the modal.
 * @param   {function}            props.closeModal         Callback to close the modal.
 * @param   {string}              props.heading            The heading of the modal.
 * @param   {string}              props.subHeading         The sub-heading of the modal.
 * @param   {string}              props.className          Additional classes for the modal to apply
 *                                                         specific styles.
 * @param   {boolean}             props.isInvalid          Whether the modal is in an invalid state.
 * @param   {React.CSSProperties} props.overlayStyle       Overlay style overrides.
 * @param   {boolean}             props.hideHeader         Boolean value to hide the modal header.
 * @param   {object}              props.contentStyle       Content style overrides.
 * @param   {string}              props.formId             ID to use for the generated form
 * @param   {Function}            props.onSubmit           Function to fire on modal submit
 * @param   {string}              props.defaultLabel       String to display on the modal's submit button - defaults to 'Submit'
 * @param   {string}              props.loadingLabel       String to display when function is running in the background - defaults to 'Submitting'
 * @param   {boolean}             props.injectSubmitButton If truthy, a button will automagically be added to the modal
 * @param   {React.JSX}           props.customSubmitButton If injectSubmitButton is falsey, provide logic for a custom submit button here
 * @param   {object}              props.rest               Additional props to pass to the
 *                                                         underlying modal. e.g., `isOpen`.
 * @returns {JSX.Element}                                  The rendered component.
 */
export function Modal({
  children,
  closeModal,
  heading,
  subHeading,
  className,
  isInvalid,
  overlayStyle,
  isOpen,
  hideHeader,
  contentStyle,
  isForm = true,
  formId,
  onSubmit,
  defaultLabel,
  loadingLabel,
  customSubmitButton = null,
  ...rest
})
{
  const modalStyle = {
    /** @type {React.CSSProperties} */
    content: {
      position: 'absolute',
      background: '#fff',
      overflow: 'auto',
      WebkitOverflowScrolling: 'touch',
      borderRadius: '4px',
      outline: 'none',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '0',
      width: '500px',
      border: '1px solid #8a8a8a',
      boxShadow: '4px 4px 4px rgba(0, 0, 0, 0.4)',
      ...contentStyle,
    },
    /** @type {React.CSSProperties} */
    overlay: {
      background: '#fff0',
      ...overlayStyle,
    },
  };

  const { t } = useTranslation();

  useEffect(() => {
      if (isOpen)
      {
        // Set timeout fixes a DOM timing problem where the query selector was returning null
        setTimeout(() => {
          // Get the first input element in the react modal and focus it
          const firstInputElement = document.querySelector?.(
            '.ReactModal__Content form input',
          );
          if (firstInputElement)
            firstInputElement.select();
          else
          {
            const focusArea = document.querySelector?.(
              '.ReactModal__Content--after-open'
            );

            if (focusArea)
              focusArea.focus();
          }
        });
      }
  }, [isOpen]);

  /**
   * Set default values for button labels here, since we can't use the useTranslation hook
   * to set a default in the function signature.
   */
  defaultLabel ??= t('submit');
  loadingLabel ??= `${t('submitting')}...`;

  // Deal with users trying to submit multiple times in a short time span.
  const [ btnLabel, setBtnLabel ] = useState(defaultLabel);
  const isCurrentlySubmitting = useRef(false);

  async function submitHandler(event)
  {
    event.preventDefault();
    if (isCurrentlySubmitting.current) return;

    setBtnLabel(loadingLabel);

    if (onSubmit)
    {
      isCurrentlySubmitting.current = true;
      await onSubmit(event);
    }

    setBtnLabel(defaultLabel);

    // Delay transitioning the state variable to after other event handlers have ran (e.g., closing
    // the modal).
    setTimeout(() => {
      isCurrentlySubmitting.current = false;
    }, 0);
  }

  return (
    <ReactModal
      style={modalStyle}
      onRequestClose={closeModal}
      contentLabel={heading}
      className={`${className} ${isInvalid ? 'invalid' : ''}`}
      isOpen={isOpen}
      {...rest}
    >
      {hideHeader ? null : (
        <header>
          <h2>{heading}</h2>
          {subHeading && <p>{subHeading}</p>}
          <button data-reset className="close" onClick={closeModal}>
            <img src={`${VITE_ASSETS_PATH}/icons/close.svg`} alt={t('close')} />
          </button>
        </header>
      )}
      { formId ? ( 
        <>
          <form id={formId} onSubmit={submitHandler}>
            {children}
          </form>
          { !customSubmitButton ? (
            <div className='buttonBar'>
              <Button primary="" form={formId} text={btnLabel} />
            </div>
          ) : ( <> { customSubmitButton } </>
          )}
        </>
      ) : (
        <> {children} </>
      )}
    </ReactModal>
  );
}
