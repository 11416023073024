/**
 *  @file       Toaster.jsx
 *  @author     Kevin Yu <kevin@distributive.network>
 * 
 *  @date       July 2024
 * 
 *              Custom hook for interacting with the toast notification
 */

import {
  Toast,
  ToastClose,
  ToastDescription,
  ToastProvider,
  ToastTitle,
  ToastViewport,
} from '@/components/Elements/Toast/Toast';
import { useToast } from '@/components/Elements/Toast/useToast';

export function Toaster() {
  const { toasts } = useToast();

  return (
    <ToastProvider swipeDirection='bottom' duration={1500}>
      {toasts.map(({ id, title, description, action, ...props }) => {
        return (
          <Toast key={id} {...props}>
            <div className="toast-content">
              {title && <ToastTitle>{title}</ToastTitle>}
              {description && (
                <ToastDescription>{description}</ToastDescription>
              )}
            </div>
            {action}
            <ToastClose />
          </Toast>
        )
      })}
      <ToastViewport />
    </ToastProvider>
  );
}
