/**
 * @file    CreditsInput.jsx - Exports a function which renders an input for credits.
 *
 * @author  Alex Huctwith <alex@kingsds.network>
 * @author  Kirill Kirnichansky <kirill@kingsds.network>
 * @author  Bryan Hoang <bryan@kingsds.network>
 * @date    April 2022, August 2022
 */
import { t } from 'i18next';
import { forwardRef } from 'react';

const handleFocus = (event) => event.target.select();

/** @typedef {import('react').Ref} Ref */
/**
 * Renders a number input for credits.
 *
 * @param  {object}      props            Component properties.
 * @param  {string}      props.name       The name and id of the input.
 * @param  {string}      props.label      The graphical label of the input.
 * @param  {object}      props.inputProps The rest of the props to pass to the underlying input
 *                                        element.
 * @param  {Ref}         ref              The ref of the input.
 * @return {JSX.Element}                  The input component.
 */
function CreditsInputWithRef({ name, label, ...inputProps }, ref)
{
  return (
    <div className="input inlineinput textinput creditsinput font-monospace">
      <label htmlFor={name}>{label} {t('in')} &supe;</label>
      <br />
      <input
        type="number"
        step="any"
        placeholder="0.000"
        name={name}
        id={name}
        ref={ref}
        onFocus={handleFocus}
        {...inputProps}
      />
    </div>
  );
}

export const CreditsInput = forwardRef(CreditsInputWithRef);
