/** @file       main.jsx
 *  @author     Alex Huctwith <alex@kingsds.network>
 *
 *  @date       April 2022
 *
 *              React app index file
 */
import React from 'react';
import { createRoot } from 'react-dom/client';
import Modal from 'react-modal';

// Make sure css files imported in child components have higher precendence due to declaration order
import './styles/dcp-style.css'; // TODO: remove this css import when the global css file lands
import './index.css'; 
import './App.css';
import '@/styles/page.css';
import '@/styles/form.css';
import '@/styles/utils.css';

import App from './App';
import { LoginOperations } from '@/features/auth';
import '@/lib/i18n';
import { AppProvider } from '@/providers/app';

// attempt login as soon as possible
if (!window.portalConnection)
  LoginOperations.load();

const appRoot = document.getElementById('root');
const root = createRoot(appRoot);

Modal.setAppElement(appRoot);

root.render(
  <React.StrictMode>
    <AppProvider>
      <App />
    </AppProvider>
  </React.StrictMode>,
);
