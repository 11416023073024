/** @file       UserPage.jsx
 *  @author     Alex Huctwith <alex@kingsds.network>
 *              Danny Akbarzadeh <danny@kingsds.network>
 *
 *  @date       April 2022
 *
 *              Portal page for displaying and managing user and identity info
 */

import { useTranslation } from 'react-i18next';

import { Layout } from '@/components/Layout';
import { Heading } from '@/components/Elements/Heading';

import { UserInfo } from '../components/UserInfo';
import { Profile } from '../components/Profile';
import { LanguageSelect } from '../components/LanguageSelect';
import { ChangePassword } from '../components/ChangePassword';

/**
 * Renders the page.
 */
export function UserPage(_props) {
  const { t } = useTranslation();
  return(
    <Layout contentClassName="userSettings" mobileHeader={t('user')}>
      <Heading heading={t('user_settings')} />
      <br></br>
      <UserInfo />
      <br></br>
      <Profile />
      <hr/>
      <LanguageSelect />
      <hr/>
      <ChangePassword />
    </Layout>
  );
}
