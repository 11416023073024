/**
 * @file    worker/utils/index.js - Exports methods used to manipulate the currently running worker.
 *
 * @author  Kirill Kirnichansky <kirill@distributive.network>
 * @date    Jan. 2023
 */

const exports = {};

exports.workerOptions = null;

/**
 * closes popup
 */
exports.closePopup = function close()
{
  document.body.setAttribute('idkfa', 'disabled');
}

/**
 * adds compute group credentials in form to exports.workerOptions
 */
exports.addCg = function addCg(form)
{
  const computeGroups = exports.workerOptions.computeGroups ?? [];

  const exists = computeGroups.some((cg) => {
      return form.joinKey.value === cg.joinKey;
  });
  if (exists) return;

  const cg = { joinKey: form.joinKey.value, joinSecret: form.joinSecret.value };
  computeGroups.push(cg);
  exports.workerOptions.computeGroups = computeGroups;
}

/**
 * removes compute group with joinKey from exports.workerOptions
 */
exports.removeCg = function removeCg(joinKey)
{
  const computeGroups = exports.workerOptions.computeGroups.filter((cg) => cg.joinKey !== joinKey);
  exports.workerOptions.computeGroups = computeGroups;
}

/**
 * sets the public compute group flag (leavePublicGroup) in exports.workerOptions
 */
exports.setPublicGroup = function setPublicGroup(form)
{
  const publicGroupRadioInput = form.elements.public;
  exports.workerOptions.leavePublicGroup = publicGroupRadioInput.value === 'no';
}

export default exports;
