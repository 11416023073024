/**
 *  @file       PrivacyPolicyPage.jsx
 *  @author     Robert Mirandola <robert@distributive.network>
 *
 *  @date       October 2022
 *
 *              Portal page for displaying and managing user and identity info
 */

import { useTranslation } from 'react-i18next';

import { Layout } from '@/components/Layout';
import { Heading } from '@/components/Elements/Heading';

export function PrivacyPolicyPage(_props) {
const { t } = useTranslation();
  return(
    <Layout contentClassName="privacyPolicy">
    <Heading heading={t('privacy_policy')}/>
    <h2>
      <span className="boldedDate">{t('privacy_policy_date')}</span>
    </h2>
    <p>{t('privacy_policy_par_1')}</p>
    <p>{t('privacy_policy_par_2')}</p>
    <h2>
      <span className="bolded">{t('our_privacy_commitments')}</span>
    </h2>
    <ul>
      <li>{t('privacy_policy_list_1')}</li>
      <li>{t('privacy_policy_list_2')}</li>
      <li>{t('privacy_policy_list_3')}</li>
      <li>{t('privacy_policy_list_4')}</li>
    </ul>
    <p>{t('privacy_policy_par_3')}</p>
    <h2>
      <span className="bolded">{t('privacy_policy_disclaimer')}</span>
    </h2>
    <p>{t('privacy_policy_par_4')}</p>
    <p>{t('privacy_policy_par_5')}</p>
    <h2>
      <span className="bolded">{t('policy_instructions')}</span>
    </h2>
    <p>{t('privacy_policy_par_6')}</p>
    <p>{t('privacy_policy_par_7')}</p>
    <p>{t('privacy_policy_par_8')}</p>
    </Layout>
  );
}
