/**
 * @file    LanguageSelect.jsx
 *
 *          Exports a React component that renders a form for the user's
 *          language preference
 *
 * @author  Robert Mirandola <robert@distributive.network>
 *
 * @date    December 2022
 */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useForm } from '@/hooks/useForm';
import { i18n } from '@/lib/i18n';
import { ErrorOperations } from '@/operations/ErrorOperations';
import { LoginOperations } from '@/features/auth';
import { Button } from '@/components/Elements';

import './LanguageSelect.css';

/**
  * @param   {object}      _props Component properties.
  * @returns {JSX.Element}        The component to render.
  */
export function LanguageSelect(_props)
{
  const { t } = useTranslation();
  const { handleSubmit } = useForm();
  const [value, setValue] = useState();
  const [changed, setChanged] = useState(false);

  useEffect(() => {
    /**
      * Handles loading current language setting from the oauth server API endpoint
      */
    async function loadProfile()
    {
      const apiUrl = window.dcpConfig.oAuth.location.resolve('/api/v1/users/locale');
      const resp = await fetch(apiUrl, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${window.oauth_token}`,
        },
      });

      if (resp.status !== 200)
        ErrorOperations.displayError('Current locale could not be fetched');
      else
      {
        let data = await resp.json();

        /**
         * If the user has never set their language directly, send a POST request to the ruby
         * endpoint to set the user's locale to their set browser locale.
         */
        if (data === null)
        {
          let langResp;
          let body;

          try
          {
            const url = window.dcpConfig.oAuth.location.resolve('api/v1/users/setlocale');

            /**
             * Check if the user's browser locale is currently supported. If it is not,
             * set the locale in the database to the fallback locale, which is English.
             */
            const supportedLngs = ['en', 'fr'];
            const browserLng = window.navigator.language.slice(0,2);

            if (supportedLngs.includes(browserLng))
              body = { locale: browserLng };
            else
              body = { locale: 'en' };

            langResp = await fetch(url, {
              method: 'POST',
              mode: 'cors',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${window.oauth_token}`,
              },
              body: JSON.stringify(body),
            });
          }
          catch
          {
            ErrorOperations.displayError('Error setting language');
          }

          if (langResp.status !== 200 && langResp.status !== 201)
            ErrorOperations.displayError('Error setting language');
          else
          {
            data = body.locale;
          }
        }
        // Set the value of the dropdown menu to the fetched language
        setValue(data);
      }
    }

    LoginOperations.load().then(() => {
      loadProfile();
    });
  }, []);

  /**
    * Handles saving language preference to the oauth server API endpoint
    * @param {SubmitEvent} event
    */
  async function savePressed(_event)
  {
    setChanged(false);
    const saveButton = document.getElementById('langSave');
    saveButton.innerHTML = `<span className="animated-ellipsis">
                              <span>.</span>
                              <span>.</span>
                              <span>.</span>
                            </span>`;

    let resp;
    try
    {
      const url = window.dcpConfig.oAuth.location.resolve('api/v1/users/setlocale');
      const body = { locale: value };
      resp = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${window.oauth_token}`,
        },
        body: JSON.stringify(body),
      });
    }
    catch
    {
      ErrorOperations.displayError('Language change could not be saved');
    }

    if (resp.status !== 200 && resp.status !== 201)
      ErrorOperations.displayError('Language choice could not be saved');
    else
    {
      // Change the language and disable the update language button
      await i18n.changeLanguage(value);
      saveButton.textContent = t('update_language_preference');
    }
  }

  /**
   * Choose whether or not to disable the update language button based on if
   * a different language has been selected compared to the current displayed language.
   */
  const handleChange = (e) => {
    if (e.target.value !== i18n.language)
      setChanged(true);
    else
      setChanged(false);

    setValue(e.target.value);
  };

  return (
    <div className="lang userContent">
      <div className="label">
        <h2 id="accountSelectHeader">{t('language_preference')}</h2>
      </div>
      <form id="langSelect" onSubmit={handleSubmit(savePressed)}>
        <select id="lang" name="lang" value={value} onChange={handleChange}>
          <option value="en">English</option>
          <option value="fr">Français</option>
        </select>
        <Button
          primary=""
          text={t('update_language_preference')}
          id="langSave"
          form="langSelect"
          disabled={!changed}
        />
      </form>
    </div>
  );
}
