/**
 *  @file       widgets/accounts-list/DCC.jsx
 *  @author     Jordan Cartlidge <jordan@kingsds.network>
 *  @author     Joash Mathew <joash@kingsds.network>
 * 
 *  @date       June 2022
 *  @date       September 2022
 * 
 *              A component which returns the balance text to a fixed decimal place.
 */

import BigNumber from 'bignumber.js';
import { t } from 'i18next';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import './DCC.css';

import { CopyToClipboard } from '@/components/Elements';

const { VITE_ASSETS_PATH } = import.meta.env;

/** @typedef {import('bignumber.js').BigNumber} BigNumber */

/**
 * Returns the balance padded to a set number of decimal points
 *
 * @param   {object}      props           Component properties.
 * @param   {BigNumber}   props.balance   DCC balance.
 * @param   {string}      props.className Additional class names to style the component.
 * @returns {JSX.Element}                 DCC balance fixed to a set number of decimal points
 */
export function DCC({ balance, className })
{
  const { i18n } = useTranslation();
  const copyRef = useRef(null);

  // Runtime check for a valid `BigNumber` value.
  if (!BigNumber.isBigNumber(balance) || balance.isNaN())
  {
    return <span>Unable to display credits</span>;
  }

  const balanceString = balance.toFixed(3);

  const [nonFractional] = balanceString.split('.');
  const shouldDisplayCompact = nonFractional.length >= 10;

  /** @type {Intl.NumberFormatOptions} */
  const numberFormatOptions = {
    minimumFractionDigits: 3,
    maximumFractionDigits: 3,
    roundingMode: 'trunc',
  };

  // Make right aligning a mix between compactly & non-compactly displayed
  // credits in a tabular format easier. e.g.,
  //           1.000B ⊇
  // 999,999,000.000  ⊇
  let unitBuffer = ' ';

  if (shouldDisplayCompact)
  {
    numberFormatOptions.notation = 'compact';
    numberFormatOptions.compactDisplay = 'short';
    unitBuffer = '';
  }

  const numberFormat = new Intl.NumberFormat(i18n.language, numberFormatOptions);
  const formattedString = `${numberFormat.format(balanceString)}${unitBuffer}`;

  return (
    <>
      <button
        className={`dcc ${className ? className : ''}`}
        type="button"
        onClick={(event) => copyRef.current.handleClickToCopy(event)}
        title={balanceString}
        data-reset
      >
        {formattedString}
        <img id="dcc" src={`${VITE_ASSETS_PATH}/icons/dcc.svg#dcc`} alt="DCCs"/>
      </button>
      <CopyToClipboard
        className="copy-balance"
        title={balanceString}
        copyText={balance}
        ref={copyRef}
        toastMessage={t('dccs_copied')}
      />
    </>
  );
}
