/** @file       MobileNavHeader.jsx
 *  @author     Kirill Kirnichansky <kirill@kingsds.network>
 *              
 *  @date       June 2022
 *
 *              navbar component that gets shown on mobile screen sizes. Features hamburger toggle for sidebar nav
 */

import { t } from 'i18next';
import './MobileNavHeader.css'

/**
 * Renders a navigation header on mobile screens.
 *
 * @param   {object}      props                component properties.
 * @param   {string}      props.header         The title to display.
 * @param   {function}    props.onOpenNav      Callback to open the nav bar.
 * @param   {string}      [props.className=''] Additional class name(s) to add to the component.
 * @returns {JSX.Element}                      The rendered component.
 */
export const MobileNavHeader = ({ onOpenNav, header, className = '' }) => {
  const { VITE_ASSETS_PATH } = import.meta.env;
  return (
    <div className={`navbar ${className}`}>
      <button data-reset className="mobile-menu-tab" onClick={onOpenNav}>
        <img src={`${VITE_ASSETS_PATH}/icons/nav-menu.svg`} alt={t('navigation_menu')} className="mobile-menu-icon" />
      </button>
      <h2 className="title">{header}</h2>
    </div>
  );
};
