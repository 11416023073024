/**
 * @file    Tooltip.jsx
 *          exports a component widget for a generic tooltip
 * @author  Maneesh Wijewardhana <maneesh@kingsds.network>
 * @date    August 2022
 */

import * as RadixTooltip from "@radix-ui/react-tooltip";

import "./Tooltip.css";

/**
 * Renders a tooltip component.
 *
 * @param   {object}                        props                Component properties.
 * @param   {boolean}                       props.open           Whether the tooltip is open.
 * @param   {React.ReactNode}               props.content        The contents of the tooltip.
 * @param   {'top'|'right'|'bottom'|'left'} props.side           Which side the tooltip opens on.
 * @param   {string}                        [props.className=''] Additional class names to pass.
 * @param   {React.ReactNode}               [props.children]     The children components.
 * @returns {JSX.Element}                                        The rendered component.
 */
export function Tooltip({
  open,
  content,
  side,
  className = '',
  children,
  asChild,
}) {
  return (
    <RadixTooltip.Provider delayDuration={500}>
      <RadixTooltip.Root open={open}>
        <RadixTooltip.Trigger asChild={asChild} data-reset>
          {children}
        </RadixTooltip.Trigger>
        <RadixTooltip.Portal>
          <RadixTooltip.Content
            side={side}
            className={`tooltipContent ${className}`}
          >
            <div className="tool-tip-content-background">{content}</div>
            <RadixTooltip.Arrow />
          </RadixTooltip.Content>
        </RadixTooltip.Portal>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  );
}
