/**
 * @file    SellAccountModal.jsx
 *          Exports a modal component for selling from an account.
 *
 * @author  Bryan Hoang <bryan@kingsds.network>, Severn Lortie <severn@distributive.network>
 * @date    June 2022
 */

import { useTranslation } from 'react-i18next';
import { Modal } from '@/components/Elements';
/**
 * Render a sell from account modal component.
 *
 * @param   {object}      props            Component props.
 * @param   {boolean}     props.isOpen     Whether the modal is open.
 * @param   {function}    props.closeModal Callback to close the modal.
 * @returns {JSX.Element}                  The rendered component.
 */
export function SellAccountModal({ isOpen, closeModal })
{
  const { t } = useTranslation();
  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeModal}
      heading={t('sell_credits_from_account')}
      defaultLabel={t('create')}
      loadingLabel={t('creating')}
    >
      <h3>{t('sell_credits_not_ready')}</h3>
    </Modal>
  );
}
