/**
 *  @file       UserInfo.jsx
 *  @author     Alex Huctwith <alex@kingsds.network>
 *              Danny Akbarzadeh <danny@kingsds.network>
 *              Kirill Kirnichansky <kirill@distributive.network>
 * 
 *  @date       April 2022
 */
import { useTranslation } from 'react-i18next';

import './UserInfo.css';

/**
 * Renders a component which displays the user's current info.
 */
export function UserInfo() {
  const { t } = useTranslation();
  return(
    <div className="userInfo card">
      <div className="label" id="keystoreTitle">
        <h2 id="id-info">{t('user_info_welcome')}</h2>
      </div>
      <div className="data">
        <p id="userEmail">{window.user_email}</p>
        <p id="userCreationDate">created <strong>{window.user_creation_date}</strong></p>
      </div>
    </div>
  );
}
