/**
 * @file    DeleteAccountModal.jsx
 *
 *          Exports a function that renders a modal component to delete an account.
 *
 * @author  Bryan Hoang <bryan@kingsds.network
 * @date    July 2022
 */
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from '@/components/Elements';
import { TextInput } from '@/components/Form';
import { useForm } from '@/hooks/useForm';
import { UserOperations } from '@/features/user';
import { AccountsDispatchContext } from '../../stores/AccountsContext';
import { AccountOperations } from '../../api/AccountOperations';

/**
 * Renders a modal to delete an account.
 *
 * @param   {object}      props            Component properties.
 * @param   {boolean}     props.isOpen     If the modal is open.
 * @param   {function}    props.closeModal Callback to close the modal.
 * @param   {object}      props.account    The account to delete.
 * @returns {JSX.Element}                  The rendered component.
 */
export function DeleteAccountModal({ isOpen, closeModal, account })
{
  const { t } = useTranslation();
  const { handleDeleteAccount } = useContext(AccountsDispatchContext);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  
  async function validatePassword() {
    const res = await UserOperations.checkPassword(watch('portal_password'));
    return res;
  }

  async function deleteAccount()
  {
    const deletedAccountDbKey = await AccountOperations.deleteAccount(account);
    closeModal();
    if (deletedAccountDbKey)
      handleDeleteAccount(deletedAccountDbKey);
  }

  return (
    <Modal 
      id="deleteAccountModal"
      isOpen={isOpen}
      closeModal={closeModal}
      heading={t('delete_account')}
      isInvalid={Object.keys(errors).length > 0}
      formId={'deleteAccountForm'}
      onSubmit={handleSubmit(deleteAccount)}
      defaultLabel={t('confirm')}
      loadingLabel={t('deleting')}
    >
      <p>{t('delete_account_modal_note')}</p>
      <TextInput
        label={t('password')}
        type="password"
        required
        {...register('portal_password', {
          validate: validatePassword,
          required: t('password_required')
        })}
      />
      {errors.portal_password && (
        <p className='errorMessage'>{t('invalid_password')}</p>
      )}
    </Modal>
  );
}
