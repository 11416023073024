/**
 * @file    Callback.jsx
 * @author  Liang Wang <liang@distributive.network>
 * @date    June 2024
 */

import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

/**
 * Redirect to the path specified by the 'state' search param, this is basically used by oauth to
 * get us to what we wanted to go before we were authenticated.
 *
 * @returns {null}
 */
export function Callback() {
  const navigate = useNavigate();
  const [searchParams, _setSearchParams] = useSearchParams();

  useEffect(() => {
    let redirectPath;

    try
    {
      redirectPath = new URL(searchParams.get('state'), window.location.origin);
    }
    catch (_e)
    {
      /* silent */
    }

    // avoid the self-loop if the user went to this endpoint directly
    if (redirectPath?.pathname !== '/auth/callback')
      navigate(redirectPath.pathname + redirectPath.search, {
        replace: true,
      });
    else
      navigate('/', {
        replace: true,
      });
  }, [navigate, searchParams]);

  // Sir, we don't serve actual pages here
  return null;
}
