/**
 *  @file       Heading.jsx
 *  @author     Alex Huctwith <alex@kingsds.network>
 *
 *  @date       April 2022
 *
 *              A component which displays a section title
 */

import './Heading.css';

/**
 * Renders a heading component.
 *
 * @param   {object}      props             The component's properties.
 * @param   {string}      props.heading     The heading to display.
 * @param   {string}      props.description Explanatory text underneath the heading.
 * @returns {JSX.Element}                   The rendered component.
 */
export function Heading({ heading, description }) {
  return (
    <div className="portal-title">
      <h1>{heading}</h1>
      {description && <p>{description}</p>}
    </div>
  );
}
