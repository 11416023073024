/**
 * @file    Profile.jsx
 *
 *          Exports a React component that renders a form for the user's
 *          profile attributes.
 *
 * @author  Alex Huctwith <alex@kingsds.network>
 *          Danny Akbarzadeh <danny@kingsds.network>
 *
 * @date    June 2022
 */
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ErrorOperations } from '@/operations/ErrorOperations';
import { LoginOperations } from '@/features/auth';
import { Button } from '@/components/Elements';
import { TextInput } from '@/components/Form';
import { debugging } from '@/lib/debugging';

import './Profile.css';

/**
 * @param   {object}      _props Component properties.
 * @returns {JSX.Element}        The component to render.
 */
export function Profile(_props)
{
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    reset,
    formState: { isDirty },
    getValues,
  } = useForm({
    shouldUseNativeValidation: true,
  });

  useEffect(() => {
    /**
     * Handles loading profile data from the oauth server API endpoint expects a GET request with
     * an Authorization header with the oauth token presented as a bearer token.
     */
    async function loadProfile()
    {
      const apiUrl =
        window.dcpConfig.oAuth.location.resolve('/api/v1/users/read');
      const resp = await fetch(apiUrl, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${window.oauth_token}`,
        },
      });

      debugging('profile') && console.debug(`Profile: 058: response = `, resp.ok, resp.status, resp.statusText);

      if (resp.ok)
      {
        const data = await resp.json();

        // using reset method allows us to set default values outside of the useForm hook
        //   this allows isDirty to work properly, thus ensuring the submission button is active/inactive when appropriate
        reset({
          first_name: data.first_name,
          last_name: data.last_name,
          country: data.country_name,
          street_address: data.street_address,
          city: data.locality,
          region: data.region,
          postal_code: data.postal_code,
        });
      }
      else if (resp.status === 401)
      {
        // oAuth token is not valid; let's log the user in properly
        return LoginOperations.load(true);
      }
      else /* (resp.status !== 200) -- some other, unspecific error */
      {
        ErrorOperations.displayError('Profile data could not be fetched');
        debugging('profile') && console.debug(`Profile: Profile fetch response - ${resp.status} ${resp.statusText}`, await resp.text());
      }
    }

    LoginOperations.load().then(() => {
      loadProfile();
    });
  }, [reset]);

  const saveProfileButtonText = t('save_changes');

  /**
   * Handles saving profile data to the oauth server API endpoint expects a POST body containing
   * relevant profile data and an Authorization header with the oauth token presented as a bearer
   * token.
   *
   * @param {SubmitEvent} event
   */
  async function savePressed(_event)
  {
    const reqBody = {
      first_name: getValues('first_name'),
      last_name: getValues('last_name'),
      postal_code: getValues('postal_code'),
      street_address: getValues('street_address'),
      locality: getValues('city'),
      region: getValues('region'),
      country_name: getValues('country'),
    };

    const apiUrl = window.dcpConfig.oAuth.location.resolve(
      '/api/v1/users/write',
    );
    let resp;
    try
    {
      resp = await fetch(apiUrl, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${window.oauth_token}`,
        },
        body: JSON.stringify(reqBody),
      });
    }
    catch
    {
      ErrorOperations.displayError('Changes could not be saved');
    }

    // If result was a 401, then we need to log in again
    if (resp.status === 401)
      return LoginOperations.load(true);

    if (!resp.ok)
    {
      const err = 'Profile data';  // Error was only profile.
      ErrorOperations.displayError(`${err} could not be saved`);
      debugging('profile') && console.warn('Profile: Profile update -', resp.ok, resp.status, resp.statusText);
    }
    else
    {
      const saveButton = document.getElementById('profile-save');
      saveButton.textContent = '✓';

      setTimeout(() => {
        saveButton.textContent = saveProfileButtonText;
        // Wait 2 seconds before signalling that the form is ready to submit again.
      }, 2000);

      reset(
        {
          first_name: reqBody.first_name,
          last_name: reqBody.last_name,
          country: reqBody.country_name,
          street_address: reqBody.street_address,
          city: reqBody.locality,
          region: reqBody.region,
          postal_code: reqBody.postal_code,
        },
        {
          keepDirty: false,
          keepTouched: false,
        },
      );
    }
  }

  return (
    <div className="profile userContent">
      <div className="label">
        <h2 id="profile-header">{t('profile')}</h2>
      </div>
      <form id="user-profile" onSubmit={handleSubmit(savePressed)}>
        <div className="form-row">
          <TextInput
            name="user-profile[first_name]"
            className="quarter-input"
            label={t('first_name')}
            autoComplete="given-name"
            {...register('first_name')}
          />
          <TextInput
            name="user-profile[last_name]"
            className="quarter-input"
            label={t('last_name')}
            autoComplete="family-name"
            {...register('last_name')}
          />
        </div>
        <div className="form-row">
          <TextInput
            name="user-profile[country]"
            label={t('country')}
            autoComplete="country-name"
            {...register('country')}
          />
        </div>
        <div className="form-row row3">
          <TextInput
            name="user-profile[street_address]"
            label={t('address')}
            autoComplete="street-address"
            {...register('street_address')}
          />
          <TextInput
            name="user-profile[locality]"
            label={t('city')}
            autoComplete="address-level2"
            {...register('city')}
          />
          <TextInput
            name="user-profile[region]"
            label={t('province_or_state')}
            autoComplete="address-level1"
            {...register('region')}
          />
          <TextInput
            name="user-profile[postal_code]"
            label={t('postal_code')}
            autoComplete="postal-code"
            {...register('postal_code')}
          />
          <Button
            primary=""
            text={saveProfileButtonText}
            id="profile-save"
            form="user-profile"
            disabled={!isDirty}
          />
        </div>
      </form>
    </div>
  );
}
