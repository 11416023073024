/**
 *  @file       Accounts.jsx
 *  @author     Alex Huctwith <alex@kingsds.network>
 *
 *  @date       April 2022
 *
 *              A component which lists accounts for users to manage in a tabular format.
 */

import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BigNumber from 'bignumber.js';

import { AccountsContext, AccountsDispatchContext } from '../../stores/AccountsContext';
import { Account } from './Account';
import { AccountOperations } from '../../api/AccountOperations';

import './Accounts.css';
import { DCC } from '../DCC';

/**
 * Renders a list of accounts in a table.
 *
 * @param   {object}      _props Component properties.
 * @returns {JSX.Element}        The table component.
 */
export function Accounts(_props)
{
  const { t } = useTranslation();
  const accounts = useContext(AccountsContext);
  const { handleRefreshAccounts } = useContext(AccountsDispatchContext);
  const [sortConfig, setSortConfig] = useState({
    direction: 'ascending',
    key: 'label',
  });

  /**
   * Calculate the total balance based on available account balances. Using a
   * ternary expression to express locked accounts (`account.balance === false`)
   * having a balance of '0'.
   */
  const totalBalance = accounts.reduce(
    (balance, account) => balance.plus(account.balance ? account.balance : 0),
    new BigNumber(0),
  );

  accounts.sort((a, b) => {
    if (a[sortConfig.key] instanceof BigNumber && sortConfig.direction === 'ascending')
      return a[sortConfig.key].minus(b[sortConfig.key]);
    if (a[sortConfig.key] instanceof BigNumber && sortConfig.direction === 'descending')
      return b[sortConfig.key].minus(a[sortConfig.key]);
    if (a[sortConfig.key] < b[sortConfig.key])
      return sortConfig.direction === 'ascending' ? -1 : 1;
    if (a[sortConfig.key] > b[sortConfig.key])
      return sortConfig.direction === 'ascending' ? 1 : -1;
    return 0;
  });

  function requestSort(key)
  {
    const options = ['ascending', 'descending'];
    const currentSortIndex = options.findIndex(option => option === sortConfig.direction)
    const nextSortIndex = (currentSortIndex + 1) % options.length;
    const nextSortDirection = options[nextSortIndex];

    setSortConfig({ key, direction: nextSortDirection });
  }

  function getClassName(name)
  {
    return sortConfig.key === name ? sortConfig.direction : 'not-selected';
  }
  
  useEffect(() => {
    async function refreshBalances()
    {
      const updated = await AccountOperations.refreshKeystores();
      handleRefreshAccounts(updated);
    }
    refreshBalances();
  }, [handleRefreshAccounts]);

  return (
    <table className="accounts-table font-monospace">
      <thead>
        <tr>
          <th>
            <button data-reset onClick={() => requestSort('label')}>
              <div>
                <div className={sortConfig.key === 'label' ? 'sort-selected' : ''}>{t('your_accounts')}</div> <span className={getClassName('label')}></span>
              </div>
            </button>
          </th>
          <th>
            <button data-reset onClick={() => requestSort('address')}>
              <div>
                <div className={sortConfig.key === 'address' ? 'sort-selected' : ''}>{t('account_numbers')}</div> <span className={getClassName('address')}></span>
              </div>
            </button>
          </th>
          <th>
            <button data-reset onClick={() => requestSort('balance')}>
              <div>
                <div className={sortConfig.key === 'balance' ? 'sort-selected' : ''}>{t('compute_credits_not_bolded')}</div> <span className={getClassName('balance')}></span>
              </div>
            </button>
          </th>
          <th>
          </th>
        </tr>
      </thead>
      <tbody>
        {accounts.map((account) => (
          <Account account={account} key={account.dbKey} />
        ))}
      </tbody>
      <tfoot>
        <tr className="totalCredits">
          <td>
            {t('total_compute_credits')}
          </td>
          {/* Adding empty cells in the footer to make styling and logic easier */}
          <td></td>
          <td className="balance">
            <DCC balance={totalBalance} />
          </td>
          <td></td>
        </tr>
      </tfoot>
    </table>
  );
}
