/** @file       widgets/button/button.jsx
 *  @author     Alex Huctwith <alex@kingsds.network>
 *
 *  @date       April 2022
 *
 *              A generic button component
 *              Props (all optional):
 *                - click: the onclick action of the button
 *                - form: relevant form that the button submits
 *                - id: the (html) id
 *                - text: the text/contents of the button
 */

/**
 * Renders a Buntton component.
 *
 * @param   {object}      props      Component properties.
 * @param   {string}      props.text Text to display on the button.
 * @param   {string}      props.form Form to submit when the button is pressed.
 * @param   {object}      props.rest Other props to pass to the underlying button.
 * @returns {JSX.Element}            Button component.
 */
export function Button({ text, form, className = '', ...rest })

{
  return (
    <button
      className={`${className}`}
      type={form ? 'submit' : 'button'}
      form={form}
      // `novalidate` by default to let react-hook-form handle validation.
      formNoValidate={Boolean(form)}
      {...rest}
    >
      {text}
    </button>
  );
}
