/** @file       widgets/input/textinput.jsx
 *  @author     Alex Huctwith <alex@kingsds.network>
 *
 *  @date       April 2022
 *
 *              A component for inputting text
 *              Props:
 *                - name: the name and id of the input
 *                - label: the graphical label of the input
 *                - placeholder: the placeholder of the input
 *                - value: the value of the input
 */
import { forwardRef } from 'react';

/**
 * Renders a text input.
 *
 * To make it easier for higher-level components (`react-hook-forms`) to
 * control the input, we use forwardRef to allow parent components to access
 * the input element's ref.
 *
 * @see     {@link https://beta.reactjs.org/learn/manipulating-the-dom-with-refs#accessing-another-components-dom-nodes}
 *
 * @param   {object}      props       Component properties.
 * @param   {string}      props.name  Name of the input.
 * @param   {string}      props.label Label of the input.
 * @param   {object}      props.rest  Other props to pass to the underlying
 *                                    input.
 * @returns {JSX.Element}             Text input component.
 */
export const TextInput = forwardRef(function TextInput({ name, label, required,  ...rest }, ref) {
  return (
    <div className="input inlineinput textinput">
      <label htmlFor={name}>{ required ? `${label} *`: label}</label>
      <br />
      <input type="text" name={name} id={name} ref={ref} required={required} {...rest} />
    </div>
  );
});
