/**
 * @file    UploadKeystoreModal.jsx
 *
 *          Modal used in Accounts page allowing users to upload keystores to the portal
 *
 * @author  Danny Akbarzadeh <danny@kingsds.network>
 * @author  Bryan Hoang <bryan@kingsds.network>
 * @date    June 2022
 */
import { useContext, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { debugging } from '@/lib/debugging';
import { useForm } from '@/hooks/useForm';
import { Button, Modal } from '@/components/Elements';
import { FileDropzone, PasswordInput, TextInput } from '@/components/Form';
import { AccountsContext, AccountsDispatchContext } from '../../stores/AccountsContext';
import { AccountOperations } from '../../api/AccountOperations';

const { Keystore } = window.dcp.wallet;
const formId = 'uploadKeystoreForm';

/**
 * Renders a Modal (made with react-modal) used in Accounts page allowing users to upload keystores
 * to the portal.
 *
 * The upload modal has to main states: selecting a file and choosing a label.
 *
 * @param   {object}      props            Component properties.
 * @param   {boolean}     props.isOpen     Whether modal is open.
 * @param   {function}    props.closeModal Callback to close the modal.
 * @returns {JSX.Element}                  The rendered component.
 */
export function UploadKeystoreModal({ isOpen, closeModal }) {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      label: '',
      password: '',
    },
    reValidateMode: 'onSubmit',
    shouldUseNativeValidation: true,
  });
  const [selectedKeystore, setSelectedKeystore] = useState(null);
  const { handleCreateAccount } = useContext(AccountsDispatchContext);
  const accounts = useContext(AccountsContext);

  // Flag for the two main states of the modal.
  const needsToSelectKeystore = !Boolean(selectedKeystore);

  // Determine the appropriate heading for the modal given the current state.
  const errorHeading = errors.label ? t('invalid_label') : '';
  const heading = errorHeading
    ? errorHeading
    : needsToSelectKeystore
    ? t('upload_bank_account_keystore')
    : t('create_label_for_keystore');

  function closeUploadModal()
  {
    reset();
    setSelectedKeystore(null);
    reset();
    closeModal(false);
  }

  // used to upload keystores chosen after upload modal's second stage
  //  (chosen in first stage using either "Browse" button or "Drop" file dropzone)
  async function uploadKeystore({ label })
  {
    selectedKeystore.label = label;
    let uploadedAccount;
    try
    {
      uploadedAccount = await AccountOperations.uploadAccount(selectedKeystore);
    }
    catch (error)
    {
      debugging('upload') && console.error('Error uploading keystore:', error);
      const message = `${t('unable_to_upload_keystore')} - ${error.message}`;
      setError('server', { type: 'server', message });
      return;
    }

    if (uploadedAccount)
      handleCreateAccount(uploadedAccount);
    closeUploadModal();
  }

  /**
   * Converts a {@link File} into a keystore.
   *
   * @throws {Error} if file is not a keystore.
   */
  async function convertFileToKeystore(file)
  {
    const keystoreText = await file.text();
    const keystoreObj = await JSON.parse(keystoreText);
    debugging('upload') && console.debug('Parsed JSON object from file:', keystoreObj);
    const keystore = await new Keystore(keystoreObj);
    setValue('label', keystoreObj.label);
    return keystore;
  }

  /**
   * Handles events selected keystore files after upload modal's first stage.
   *
   * @param {File} file The file selected by the user.
   */
  async function onSelect(file)
  {
    if (Array.isArray(file))
    {
      [file] = file;
    }

    try
    {
      const keystore = await convertFileToKeystore(file);
      clearErrors('keystoreFile');
      setSelectedKeystore(keystore);
    }
    catch (error)
    {
      setError('keystoreFile', { type: 'custom', message: t('invalid_keystore_file') });
    }
  }

  function validateUniqueAccountLabel(label)
  {
    if (accounts.some((account) => account.label === label))
    {
      return false;
    }

    return true;
  }

  async function validateAccountPassword(password)
  {
    try
    {
      await selectedKeystore.unlock(password, 200, false);
      return true;
    }
    catch (error)
    {
      return t('incorrect_password');
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeUploadModal}
      heading={heading}
      isInvalid={Object.keys(errors).length > 0}
      formId={formId}
      onSubmit={handleSubmit(uploadKeystore)}
      defaultLabel={t('upload')}
      loadingLabel={t('uploading')}
      customSubmitButton={
        <div className="buttonBar">
          {/* Conditionally render the "action" button. */}
          {needsToSelectKeystore ? (
            <Button
              text={t('browse')}
              primary=""
              onClick={() => {
                document.getElementById('fileDropzone').click()
              }}
            />
          ) : (
            <Button primary="" text={t('upload')} type={t('submit')} form={formId} />
          )}
        </div>
      }
    >
      {/* Control file dropzone input to let us use `react-hook-form` to validate the file in the future. */}
      <Controller
        render={({ field: { onChange } }) => (
          // Let `react-hook-form` library know about changed value for file input.
          <FileDropzone
            onDrop={onSelect}
            onChange={(event) => onChange(event.target.files[0])}
            accept={{ 'application/json': ['.keystore'] }}
            multiple={false}
          />
        )}
        name="keystoreFile"
        control={control}
      />
      <p className="errorMessage">{errors.keystoreFile?.message}</p>
      {/* Conditionally render the label in 2nd stage. */}
      {!needsToSelectKeystore && (
        <>
          <TextInput
            label={t('label')}
            autoComplete="username"
            autoFocus
            {...register('label', {
              validate: {
                unique: validateUniqueAccountLabel,
              },
              required: t('label_is_required'),
            })}
          />
          <p>{errors.label?.message}</p>
          <PasswordInput
            label={t('password')}
            autoComplete="current-password"
            {...register('accountPassword', {
              validate: validateAccountPassword,
            })}
          />
          <p className="errorMessage">{errors.accountPassword?.message}</p>
          <p className="errorMessage">{errors.server?.message}</p>
        </>
      )}
    </Modal>
  );
}
