/**
 * @file    AccountContext.jsx
 *
 *          Contains wiring related to state management for accounts.
 *
 *          Combines React's Reducer and Context APIs by exporting a "Provider"
 *          component. It provides context to its children for accessing the
 *          `accounts` state and to modify it through events.
 *
 *          Centralizes the logic for the state transition logic for accounts
 *          in the accountsReducer method.
 *
 * @see     {@link https://beta.reactjs.org/learn/scaling-up-with-reducer-and-context}
 *
 * @author  Bryan Hoang <bryan@kingsds.network>
 * @date    June 2022
 */

import { createContext , useEffect, useMemo, useReducer } from 'react';



/**
 * This is _not_ the same Keystore object in dcp-client! This object has the additional `unlocked` tacked
 * on for better react interopt. Otherwise, it's the same object.
 * @typedef {Object} Keystore
 * @property {boolean} unlocked - whether the account keystore is unlocked right now, although
 *                              keystore has an `isUnlocked()`, the function isn't pure so
 *                              this is our way to making it interact with react better
 */

/**
 * @type {Keystore[]}
 */
const initialAccounts = [];

/**
 * Context that used by other components to access the `accounts` state.
 */
export const AccountsContext = createContext(initialAccounts);

/**
 * Context that is used by other components to change the `accounts` state
 * through dispatching events that trigger state transitions in
 * {@link accountsReducer}.
 */
export const AccountsDispatchContext = createContext({
  // JSDoc comments and initial context value is non-null for type hinting.
  /**
   * @param {Keystore} account The account **created** to add to the list of
   *                         accounts.
   */
  handleCreateAccount: (account) => {},
  /**
   * @param {Keystore[]} accounts The accounts **read** from the server to
   *                            replace the current list of accounts. i.e.,
   *                            'refreshing'. 
   */
  handleRefreshAccounts: (accounts) => {},
  /**
   * @param {Keystore[]} account The account(s) **updated** in some way.
   */
  handleUpdateAccounts: (accounts) => {},
  /**
   * @param {object} dbKey The db id of the account **deleted**.
   */
  handleDeleteAccount: (dbKey) => {},
});

/**
 * Provides context to its children for accessing the `accounts` state and
 * dispatching events to update it.
 *
 * Uses the Reducer API to manage manage the non-trivial state transitions for
 * the list of accounts.
 *
 * @param   {object}          props          Component props.
 * @param   {React.ReactNode} props.children Children of the component.
 * @returns {JSX.Element}                    The provider component.
 */
export function AccountsProvider({ children })
{
  const [accounts, dispatch] = useReducer(
    accountsReducer,
    /**
     * Empty by default until accounts are fetched (refreshed) from the server.
     */
    initialAccounts,
  );

  /**
   * The main authoritative state object for the accounts. In addition to
   * holding the state, also contains the logic for dispatching events to
   * update the state.
   *
   * i.e., the **CRUD** operations.
   *
   * Memoizing to avoid unnecessary re-renders based on new values of the
   * context created on each render.
   */
  const accountsDispatchContext = useMemo(() => {
    return {
      /** @type{(Keystore) => void} */
      handleCreateAccount: (account) =>
        dispatch({
          type: 'created',
          payload: {
            account,
          },
        }),

      /** @type{(Keystore[]) => void} */
      handleRefreshAccounts: (accounts) =>
        dispatch({
          type: 'refreshed',
          payload: {
            accounts,
          },
        }),

      /** @type{(Keystore[]) => void} */
      handleUpdateAccounts: (accounts) =>
        dispatch({
          type: 'updated',
          payload: {
            accounts,
          },
        }),
      handleDeleteAccount: (dbKey) =>
        dispatch({
          type: 'deleted',
          payload: {
            dbKey,
          },
        }),
    };
  }, []);

  useEffect(() => {
    const updateHandlers = [];
    for (const account of accounts)
    {
      const handleUpdate = () => {
        accountsDispatchContext.handleUpdateAccounts([account]);
      };
      updateHandlers.push(handleUpdate);
      account.on('lock', handleUpdate);
    }


    return () => {
      for (let i = 0; i != updateHandlers.length; ++i)
      {
        const account = accounts[i];
        const handler = updateHandlers[i];
        account.off('lock', handler);
      }
    }
  }, [accounts, accountsDispatchContext]);

  return (
    <AccountsContext.Provider value={accounts}>
      <AccountsDispatchContext.Provider value={accountsDispatchContext}>
        {children}
      </AccountsDispatchContext.Provider>
    </AccountsContext.Provider>
  );
}

/**
 * @description Applies centralized state transition logic for accounts.
 *
 * Based in the dispatched event (action), it will update the state of the
 * accounts list accordingly. The possible actions triggering state transitions
 * are:
 *
 * @typedef  {object}      refreshedAction  Object describing the action of
 *                                          refreshing all accounts from the 
 *                                          server.
 * @property {'refreshed'} type             Type of action,
 * @property {object}      payload          Payload of the action,
 * @property {object[]}    payload.accounts The accounts to replace the current
 *                                          list of accounts with.
 *
 * @typedef  {object}    createdAction   Object describing the action of
 *                                       creating a new account.
 * @property {'created'} type            Type of action,
 * @property {object}    payload         Payload of the action,
 * @property {object}    payload.account The newly created account to add to
 *                                       the list of accounts.
 *
 * @typedef  {object}    updatedAction    Object describing the action of
 *                                        updating an account. For example,
 *                                        changing the label, changing the
 *                                        password, changing the balance, etc.
 * @property {'updated'} type             Type of action,
 * @property {object}    payload          Payload of the action,
 * @property {object}    payload.accounts The accounts to update in the list of
 *                                        accounts.
 *
 * @typedef  {object}    deletedAction   Object describing the action of
 *                                       deleting an account.
 * @property {'deleted'} type            Type of action,
 * @property {object}    payload         Payload of the action,
 * @property {object}    payload.address The address of the account remove from
 *                                       the list of accounts.
 *
 * @param {Account[]} accounts The previous list of accounts (previous state).
 * @param {refreshedAction | createdAction | updatedAction | deletedAction} action Object describing the event that triggered the state transition.
 * @throws {Error} If the action type is not recognized.
 * @returns {Account[]} The updated list of accounts (new state).
 */
function accountsReducer(accounts, { type, payload })
{
  switch (type)
  {
    case 'refreshed':
    {
      for (const account of payload.accounts)
        account.unlocked = account.isUnlocked();
      return payload.accounts;
    }
    case 'created':
    {
      const newAccount = payload.account;
      newAccount.unlocked = newAccount.isUnlocked();
      const newAccounts = [
        ...accounts,
        newAccount,
      ];

      /**
       * Sort by label by default to match the order of the accounts returned by
       * the server.
       */
      newAccounts.sort((account1, account2) => {
        return account1.label.localeCompare(account2.label);
      });

      return newAccounts;
    }
    /**
     * Modified an account in some way. i.e.,
     * - balance changed
     * - label changed
     * - password changed
     * - wallet is locked/unlocked
     */
    case 'updated':
    {
      return accounts.map((account) => {
        /** @type{Keystore} */
        const updated = payload.accounts
          .filter(a => !!a?.address) // only process valid Accounts with Addresses
          .find((updatedAccount) => account.dbKey === updatedAccount.dbKey);
        if (updated)
        {
          updated.unlocked = updated.isUnlocked();
          return updated;
        }

        account.unlocked = account.isUnlocked();
        return account;
      });
    }
    // Deleted an account.
    case 'deleted':
    {
      return accounts.filter((account) => account.dbKey !== payload.dbKey);
    }
    default:
    {
      throw Error(`Unknown action: ${type}`);
    }
  }
}
