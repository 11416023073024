/**
 * @file    main.jsx
 *          exports MainErrorFallback component
 * @author  Ruslan Kain <ruslan@distributive.network>
 * @date    Aug. 2024
 */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from '@/components/Elements';

import './MainErrorFallback.css';

const formId = 'errorForm';

/**
 * Renders a modal based on the uncaught error it receives.
 *
 * @param {object} props       Component properties.
 * @param {any}    props.error An uncaught error.
 * @returns {JSX.Element}      The rendered component.
 */
export function MainErrorFallback({ error })
{
  const { t } = useTranslation();
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  // Set ErrorModalOpen state variable to true to open modal on uncaught error on page render
  useEffect (() => {
    setIsErrorModalOpen(true);
  }, []);
  
  // Set ErrorModalOpen state variable to false when error modal window is closed
  function closeErrorModal()
  {
    setIsErrorModalOpen(false);
  }

  return (
    <Modal
      isOpen={isErrorModalOpen} 
      closeModal={closeErrorModal}
      heading={t('error.uncaught', { ns: 'validation' })}
      onSubmit={closeErrorModal}
      formId={formId}
      defaultLabel={t('Close')}
      loadingLabel={t('Closing')}
    >
      {error?.message ? (
        <>
          <p className="error-message">{error.message}</p>
          <br />
        </>
      ) : null}
      <p>Please be patient while we work on getting this fixed</p>
    </Modal>
  );
}
