/**
 * @file    ConfirmDonationModal.jsx
 *          Exports a modal component that thanks users for their donation.
 *
 * @author  Bryan Hoang <bryan@kingsds.network>, Severn Lortie <severn@distributive.network>
 * @date    February 2024
 */

import { useTranslation, Trans } from 'react-i18next';
import { Modal } from '@/components/Elements';
import Confetti from 'react-confetti';
/**
 * Render a donation confirmation modal.
 *
 * @param   {object}      props                 Component props.
 * @param   {boolean}     props.isOpen          Whether the modal is open.
 * @param   {function}    props.closeModal      Callback to close the modal.
 * @param   {function}    props.amount          Amount of DCC that was donated.
 * @param   {function}    props.toAccountLabel  Label of the account receiving the donation.
 * @returns {JSX.Element}                       The rendered component.
 */
export function ConfirmDonationModal({ isOpen, closeModal, amount, toAccountLabel })
{
  const { t } = useTranslation();
  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeModal}
      heading={t('donate_credits_from_account')}
      defaultLabel={t('create')}
      loadingLabel={t('creating')}
      contentStyle={{overflow: 'hidden'}}
    >
      <Confetti 
        width={600}
        height={600}
        style={{position: 'fixed', left: -40}}
        recycle={false}
        run={isOpen}
      />
      <p>
        <Trans i18nKey={'donation_thank_you'} values={{amount, toAccountLabel}}> 
          🎉 Thank you so much for your donation of <span>{{amount}}</span> Compute Credits to <span>{{toAccountLabel}}</span>! Science thanks you 📡 🔬 🧬
        </Trans>
      </p>
    </Modal>
  );
}
