/**
 * @file    UserContext.jsx
 *
 *          Context for storing user preferences.
 *
 *          Exposes two custom hooks for getting and setting the user preferences state.
 *
 * @author  Jordan Cartlidge <jordan@kingsds.network>
 * @date    June 2022
 */

import { useContext, createContext, useState } from 'react';

const UserPreferencesContext = createContext();
const UserPreferencesUpdateContext = createContext();

/**
 * Hook that provides access to the userPreferences state
 *
 * @returns {object}                    The current userPreferences context value
 */
export function useUserPref()
{
  return useContext(UserPreferencesContext);
}

/**
 * Hook that wraps the user preferences state setter. Creates a new object which contains the contents
 * of our old state and replaces those values with any new values passed in.
 *
 * @returns {function}                   Function for changing userPreferences
 */
export function useUserPrefUpdate()
{
  const setUserPreferences = useContext(UserPreferencesUpdateContext);
  return ((preferences) => {
    setUserPreferences((oldState) => {
      const newState = {
        ...oldState,
        ...preferences,
      }
      
      return newState;
    });
  });
}

/**
 * Provides context to its children for accessing the `userPreferences` state and
 * dispatching events to update it.
 *
 * @param   {object}          props          Component props.
 * @param   {React.ReactNode} props.children Children of the component.
 * @returns {JSX.Element}                    The provider component.
 */
export function UserProvider({ children })
{
  const userPreferencesDefault = {
    "showAboutModalOnLogin": false,
    "showWarnModalOnLogin": true,
  }

  const [userPreferences, setUserPreferences] = useState(userPreferencesDefault);
  
  return (
    <UserPreferencesContext.Provider value={userPreferences}>
      <UserPreferencesUpdateContext.Provider value={setUserPreferences}>
        {children}
      </UserPreferencesUpdateContext.Provider>
    </UserPreferencesContext.Provider>
  )
}
