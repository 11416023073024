/**
 *  @file       useDCPWorker.js
 *  @author     Kirill Kirnichansky <kirill@distributive.network>
 *              David Courtis <david@distributive.network>
 *  @date       April 2023
 *              Feb. 2024
 *
 *  We need a dcp worker component in both WorkerPanel and SidebarWorker, so instead of
 *  having the originManager setting logic and toggleWorker in two places, this wrapper hook handles it one place.
 */

import { useContext, useEffect } from 'react';
import { useDCPWorker as useDCPWorkerHook } from 'use-dcp-worker';
import { AuthContext } from '@/features/auth/stores/AuthContext.jsx';

/**
 *  This hook wraps the use-dcp-worker hook and is responsible for setting the 
 *  originManager in the window and adding toggleWorker to the library returned.
 */
export function useDCPWorker(options)
{
  const { idleTimer } = useContext(AuthContext);
  const lib = useDCPWorkerHook(options);

  lib.toggleWorker = () => {
    if (!lib.worker)
    {
      console.error('Worker is not loaded. use-dcp-worker failed?');
    }
    else if (lib.workerState.working)
    {
      lib.worker.stop().catch((error) => 
        console.error('Stopping the worker threw an unexpected error:', error)
      );
    }
    else
    {
      lib.worker.start().catch((error) => 
        console.error('Starting the worker threw an unexpected error:', error)
      );
    }
  }

  // show how many workers are working in the title
  useEffect(() => {
    document.title = `DCP Portal | ${lib.workerState.workingSandboxes} working`;
  }, [lib.workerState.workingSandboxes]);

  // useEffect since this is a side effect of rendering + perf
  useEffect(() => {
    if (lib.worker)
      window.dcpWorker = lib.worker;
  }, [lib.worker]);

  // useEffect to pause the timer iff worker is working
  useEffect(() => {
    lib.worker?.on('start', () => idleTimer.pause());
    lib.worker?.on('end', () => idleTimer.resume());

    return () => {
      lib.worker?.off('start', () => idleTimer.pause());
      lib.worker?.off('end', () => idleTimer.resume());
    }
  }, [idleTimer, lib.worker]);

  return lib;
}
