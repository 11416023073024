/**
 * @file    useIdleTimeout.js
 * 
 *          Hook for initializing the useidleTimer hook with proper properties.
 *
 * @author  David Courtis <david@distributive.network>
 * @date    Feb. 2024
 */
import { useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { LoginOperations } from '@/features/auth';

/**
 * Hook for initializing the useidleTimer hook with proper properties.
 *
 * @param onIdle   function to notify user when idle timeout is close
 * @param idleTime number of seconds to wait before user is logged out
 */
export const useIdleTimeout = ({ onIdle, idleTime = 10000 }) => {
  const MODAL_TIMEOUT = Number(import.meta.env.VITE_IDLE_MODAL_TIMEOUT);
  const [isIdle, setIdle] = useState(false);

  const handleIdle = () => {
    setIdle(true);
    LoginOperations.handleLogout();
  }

  const idleTimer = useIdleTimer({
    timeout: idleTime,
    promptBeforeIdle: MODAL_TIMEOUT,
    onPrompt: onIdle,
    onIdle: handleIdle,
    debounce: 1000
  })

  return {
    isIdle,
    setIdle,
    idleTimer
  }
}
