/**
 * @file    AboutModal.jsx
 *          Exports a modal component for creating keystores/accounts.
 *
 *          Uses `react-hook-forms` to handle form validation.
 *
 * @author  Jordan Cartlidge <jordan@kingsds.network>
 * @author  Kevin Yu <kevin@distributive.network>
 * @date    June 2022
 */

import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import { Modal } from '@/components/Elements';
import { Button } from '@/components/Elements';
import { Heading } from '@/components/Elements/Heading';
import { PatchNotesModal } from '@/features/misc';

import './AboutModal.css';


const createModalStyle = {
  width: '800px',
  top: '50%',
  left: '50%',
  right: 'auto',
  bottom: 'auto',
  marginRight: '-50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '100%',
  borderRadius: '0',
  boxShadow: 'rgba(50, 50, 93, 0.25) 0 6px 12px -2px, rgba(0, 0, 0, 0.3) 0 3px 7px -3px',
  border: 'none'
};

const checkboxId = 'aboutModalCheckbox';
const { VITE_ASSETS_PATH } = import.meta.env;

/**
 * Render a create keystore modal component.
 * @param   {object}      props                  Component props.
 * @param   {boolean}     props.isOpen           Whether the modal is
 *                                               open.
 * @param   {function}    props.closeModal       Callback to close the modal.
 * @param   {function}    props.onSubmit         Callback after submitting
 *                                               the form.
 * @returns {JSX.Element}                        The rendered component.
 */
export function AboutModal({
  isOpen,
  closeModal,
  onSubmit,
  doNotShowAboutModalOnLogin,
})
{
  const { t } = useTranslation();
  const { register, handleSubmit } = useForm();
  const [isPatchNotesModalOpen, setIsPatchNotesModalOpen] = useState(false);


  function updateUserPreferences({ doNotShowAboutModalOnLogin })
  {
    onSubmit(doNotShowAboutModalOnLogin);
    closeModal();
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        contentStyle={createModalStyle}
        overlayStyle={{
          zIndex: 1050,
        }}
        hideHeader={true}
        heading=""
        contentLabel="About Modal"
        id="aboutModal"
        formId={'aboutModalForm'}
        onSubmit={handleSubmit(updateUserPreferences)}
        customSubmitButton={<></>}
      >
        <div className="about-header">
          <img src={`${VITE_ASSETS_PATH}/img/logo-banner.png`} alt="DCP" className="about-banner-logo" />
        </div>
        <section>
          <Heading heading={t('welcome_to_distributive_compute_protocol')} />

          {/* Company Specific Disclaimer */}
          <div className='branded-content'>
            <p><Trans i18nKey={'disclaimer'} ns='copy'></Trans></p>
            <hr />
            <p className='about-powered-by-title'><b><Trans i18nKey={'powered_by_dcp'} ns='copy'></Trans></b></p>
          </div>

          <p>
            {t('about_page_par_1')}
          </p>
          <p>
            <Trans i18nKey={'about_page_par_2'} values={{ worker: t('worker') }}>
              Use the <b>Worker</b> tab to use your computer cores to contribute to
              world wide distributed compute projects (and earn Compute Credits while you do).
            </Trans>
          </p>
          <p>
            <Trans i18nKey={'about_page_par_3'} values={{ accounts: t('accounts') }}>
              Check out the <b>Accounts</b> tab to see and manage your Compute Credits.
            </Trans>
            {t('about_page_par_4')}&nbsp;<a href="https://docs.dcp.dev/" target="_blank" rel="noreferrer">{t('documentation')}</a>
            {t('about_page_par_4_ending')}{' '}
            <a href="mailto:info@distributive.network">
              {t('email')}
            </a>
            {t('period')}
          </p>
          <div className="dont-show-again-checkbox">
            <input
              id={checkboxId}
              type="checkbox"
              value={true}
              defaultChecked={doNotShowAboutModalOnLogin}
              {...register('doNotShowAboutModalOnLogin')}
            />
            <label htmlFor={checkboxId}>{t('do_not_show_on_login_again')}</label>
          </div>
          <Button primary="" text={t('close')} type="submit" onClick={closeModal} />
        </section>

        <img src={`${VITE_ASSETS_PATH}/img/powered-by-dcp.png`} alt="DCP" className="about-powered-by" />
      </Modal>
      <PatchNotesModal
        isOpen={isPatchNotesModalOpen}
        closeModal={() => setIsPatchNotesModalOpen(false)}
      />
    </>
  );
}
