/**
 * @file    SellTab.jsx
 * @author  Severn Lortie <severn@distributive.network>
 * @author  Alex Huctwith <alex@kingsds.network>
 * @author  Bryan Hoang <bryan@distributive.network>
 * @author  Robert Mirandola <robert@distributive.network>
 * @date    April 2022, September 2022
 */

import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useForm } from '@/hooks/useForm';
import { Button } from '@/components/Elements';
import {
  AccountsContext,
  AccountsDispatchContext,
} from '../../../stores/AccountsContext';
import { AccountSelect } from '../../AccountSelect';
import { AccountOperations } from '../../../api/AccountOperations';
import { CreditsInput } from '../../CreditsInput';
import { SellAccountModal } from '../../Modals';

const { location, payoutsPath, clientId } = window.dcpConfig.oAuth;
const path = `${location.resolve(payoutsPath)}?uid=${clientId}`;

/**
 * Renders a component which displays an interface for selling credits.
 *
 * @param   {object}      _props Component properties.
 * @returns {JSX.Element}        The rendered component.
 */
export function SellTab(_props)
{
  const { t } = useTranslation();
  const accounts = useContext(AccountsContext);
  const { handleUpdateAccounts } = useContext(AccountsDispatchContext);
  const { register, handleSubmit, formState: { errors } } = useForm({
    reValidateMode: 'onChange',
  }); 
  const [isSellModalOpen, setIsSellModalOpen] = useState(false);
  function closeSellModal()
  {
    setIsSellModalOpen(false);
  }
  function openSellModal()
  {
    setIsSellModalOpen(true);
  }
  function sellCredits({accountSelected, amount})
  {
    const fromAccount = accounts.find(account => account.dbKey === Number(accountSelected));
    if (!dcpConfig.rollout.enableSell)
    {
      openSellModal();
      return;
    }
    window.open(`${path}&fromAccount=${fromAccount.dbKey}&amount=${amount}`, 'Sell Credits', 'height=800,width=600');
    window.addEventListener('message', async (event) => {
      if (event.origin !== location.origin)
        return;
      switch (event.data)
      {
        case 'success':
        {
          const updatedAccounts = await AccountOperations.refreshBalance(accounts);
          if (updatedAccounts)
            handleUpdateAccounts(updatedAccounts);
          break;
        }
        case 'failure':
          break;
      }
    }, false);
  }

  return (
    <>
      <form id="sell-form" onSubmit={handleSubmit(sellCredits)}>
        <AccountSelect 
          label={t('from')}
          placeholder={t('select_account')}
          accounts={accounts}
          error={errors.accountSelected}
          {...register('accountSelected', 
          {
            required: t('must_specify_account_to_sell'),
          })} />
        {errors.accountSelected && <p>{errors.accountSelected?.message}</p>}
        <CreditsInput 
          label={t('amount')} 
          name="buyForm[amount]"
          className={`${errors.amount ? 'invalid' : ''}`}
          {...register('amount', {
            required: t('amount_to_sell_is_required'),
          })}/>
        {errors.amount && <p>{errors.amount?.message}</p>}
        <Button primary="" text={t('sell')} form="sell-form" />
      </form>
      <SellAccountModal
        isOpen={isSellModalOpen}
        closeModal={closeSellModal}
      />
    </>
  );
}
