/**
 * @file    i18n.js - Configuration file for the i18next internationalization
 *                    library. See https://www.i18next.com/ for information.
 *
 * @author  Robert Mirandola <robert@distributive.network>
 * @date    Oct. 2022
 */

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

import { debugging } from './debugging';

const { VITE_GIT_REV, VITE_ASSETS_PATH } = import.meta.env;

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(HttpApi)
  .init({
    supportedLngs: ['en', 'fr'],
    ns: [
      // Generic bucket to throw translations under for the app.
      'app',
      // Text that's reused everywhere, like "Confirm" and "Cancel" on buttons.
      'common',
      // Text for validation messages, like "email address not valid" in a form.
      'validation',
      // "Copy text" for branded versions of the app.
      'copy',
      // Feature/page specific translations.
      'accounts',
    ],
    defaultNS: 'app',
    fallbackNS: ['common', 'validation'],
    nonExplicitSupportedLngs: true, /* e.g., 'en-CA' is coerced into 'en' */
    fallbackLng: 'en', /* Fallback to English translation JSON file if locale is not correctly set */
    detection: {
      order: ['navigator'] /* Use window.navigator.language to detect language if the user does not set their locale */,
      caches: [] /* Disable storing the current language in a cookie and in localStorage */
    },
    /** @type {import('i18next-http-backend').HttpBackendOptions} */
    backend: {
      loadPath([language], [namespace]) {
        const [mainLanguage] = language.split('-');
        if (namespace === 'copy')
          return `${VITE_ASSETS_PATH}/${namespace}.${language}.json`;
        return `/locales/${mainLanguage}/${namespace}.json`;
      },
      queryStringParams: { v: VITE_GIT_REV },
    },
    react: { useSuspense: false },
    debug: debugging('i18next'),
  });

export { i18n };
