/**
 *  @file       useToast.jsx
 *  @author     Kevin Yu <kevin@distributive.network>
 * 
 *  @date       July 2024
 * 
 *              Custom hook for interacting with the toast notification
 */

import * as React from 'react';

const TOAST_LIMIT = 1;
const TOAST_REMOVE_DELAY = 1000000;

let count = 0;

function genId() {
  count = (count + 1) % Number.MAX_SAFE_INTEGER;
  return count.toString();
}

const toastTimeouts = new Map();

const addToRemoveQueue = (toastId) => 
{
  if (toastTimeouts.has(toastId)) 
    return;

  const timeout = setTimeout(() => {
    toastTimeouts.delete(toastId);
    dispatch({
      type: 'REMOVE_TOAST',
      toastId: toastId,
    })
  }, TOAST_REMOVE_DELAY);

  toastTimeouts.set(toastId, timeout);
}

export const reducer = (state, action) => 
{
  switch (action.type) 
  {
    case 'ADD_TOAST':
      return {
        ...state,
        toasts: [action.toast, ...state.toasts].slice(0, TOAST_LIMIT),
      };

    case 'UPDATE_TOAST':
      return {
        ...state,
        toasts: state.toasts.map((t) =>
          t.id === action.toast.id ? { ...t, ...action.toast } : t
        ),
      };

    case 'DISMISS_TOAST': 

      if (action.toastId) 
      {
        addToRemoveQueue(action.toastId);
      } 
      else 
      {
        // eslint-disable-next-line no-shadow
        state.toasts.forEach((toast) => {
          addToRemoveQueue(toast.id)
        })
      }

      return {
        ...state,
        toasts: state.toasts.map((t) =>
          t.id === action.toastId || action.toastId === undefined
            ? {
              ...t,
              open: false,
            }
            : t
        ),
      };
    case 'REMOVE_TOAST':
      if (action.toastId === undefined) 
        return {
          ...state,
          toasts: [],
        };
      return {
        ...state,
        toasts: state.toasts.filter((t) => t.id !== action.toastId),
      };
  }
}

const listeners = [];

let memoryState = { 
  toasts: [],
};

function dispatch(action) 
{
  memoryState = reducer(memoryState, action);
  listeners.forEach((listener) => {
    listener(memoryState)
  });
}


function toast({ ...props }) 
{
  const id = genId();
  
  // eslint-disable-next-line no-shadow
  const update = (props) =>
    dispatch({
      type: 'UPDATE_TOAST',
      toast: { ...props, id },
    });
  const dismiss = () => dispatch({ type: 'DISMISS_TOAST', toastId: id });

  dispatch({
    type: 'ADD_TOAST',
    toast: {
      ...props,
      id,
      open: true,
      onOpenChange: (open) => {
        if (!open) dismiss()
      },
    },
  });

  return {
    id: id,
    dismiss,
    update,
  };
}

function useToast() 
{
  const [state, setState] = React.useState(memoryState);

  React.useEffect(() => {
    listeners.push(setState);
    return () => {
      const index = listeners.indexOf(setState);
      if (index > -1) {
        listeners.splice(index, 1);
      }
    }
  }, [state]);

  return {
    ...state,
    toast,
    dismiss: (toastId) => dispatch({ type: 'DISMISS_TOAST', toastId }),
  };
}

export { useToast, toast };
