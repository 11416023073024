/**
 * @file    DownloadKeystoreModal.jsx
 *
 *          Exports a function that renders a modal component to download an account.
 *
 * @author  Jordan Cartlidge <jordan@kingsds.network>
 * @date    August 2022
 */

import { useTranslation } from 'react-i18next';

import { PasswordInput } from '@/components/Form';
import { Modal } from '@/components/Elements';
import { useForm } from '@/hooks/useForm';

const formId = 'downloadAccountForm';
let errorMessage = '';

/**
 * Renders a modal to download a keystore.
 *
 * @param   {object}      props            Component properties.
 * @param   {boolean}     props.isOpen     If the modal is open.
 * @param   {function}    props.closeModal Callback to close the modal.
 * @param   {object}      props.account    The account to download.
 * @returns {JSX.Element}                  The rendered modal.
 */
export function DownloadKeystoreModal({ isOpen, closeModal, account })
{
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  
  function validatePasswords()
  {
    // verifying passwords match and that password fields are not empty
    if (watch('passphrase', '') !== watch('confirmPassphrase', ''))
    {
      errorMessage = 'Passphrase confirmation must match'
      return false;
    }

    return true;
  }

  function resetAndCloseModal()
  {
    errorMessage = ''
    reset();
    closeModal();
  }

  /**
   * Update and click on the #downloadLink element to download a keystore.
   *
   * @param {object} account The keystore to download.
   */
  function downloadKeystore(account) {
    const payload = `data:text/json;charset=utf-8,${encodeURIComponent(
      JSON.stringify(account),
    )}`;
    const downloadLink = document.getElementById('downloadLink');
    downloadLink.setAttribute('href', payload);
    downloadLink.setAttribute('download', `${account.label}.keystore`);
    downloadLink.click();
  };

  /**
   * Create new keystore with a passphrase using private key and download that keystore.
   *
   * @param {object} passphrase The passphrase we will lock the keystore with.
   */
  async function submitDownloadForm({ passphrase })
  {
    const pk = await account.getPrivateKey();
    const newKeystore = await new window.wallet.Keystore(pk, passphrase);
    newKeystore.label = account.label;

    downloadKeystore(newKeystore);
    resetAndCloseModal();
  }
  
  return (
    <Modal
      isOpen={isOpen}
      closeModal={resetAndCloseModal}
      heading={t('download_account_keystore')}
      isInvalid={Object.keys(errors).length > 0}
      formId={formId}
      onSubmit={handleSubmit(submitDownloadForm)}
      defaultLabel={t('download')}
      loadingLabel={t('downloading')}
    >
      <p>
        {t('download_keystore_par_1')}
        <br />
        <br />
        {t('download_keystore_par_2')}
      </p>
      <PasswordInput
        label={t('new_passphrase')}
        {...register('passphrase', {
          validate: validatePasswords,
          required: t('passphrase_cannot_be_empty'),
        })}
      />
      <PasswordInput
        label={t('confirm_new_passphrase')}
        {...register('confirmPassphrase', {
          validate: validatePasswords,
          required: t('passphrase_cannot_be_empty'),
        })}
      />
      {(errors.passphrase || errors.confirmPassphrase) && (
        <p className="errorMessage">
          {errors.passphrase.type === 'required'
            ? errors.passphrase.message
            : errorMessage}
        </p>
      )}
    </Modal>
  );
}
