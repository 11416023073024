/**
 * @file    WarnAccountModal.jsx
 *
 *          Exports a function that renders a modal component to warn on high credit accounts with
 *          no password.
 *
 * @author  Nazila Akhavan <nazila@distributive.network>
 * @date    Aug 2024
 */

import { useContext, useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import BigNumber from 'bignumber.js';

import { AccountsContext } from '@/features/accounts/stores/AccountsContext';
import { useUserPref, useUserPrefUpdate } from '@/features/user';
import { Modal } from '@/components/Elements';
import { useForm } from '@/hooks/useForm';

const formId = 'warningForm';

/**
 * Renders a modal to delete an account.
 *
 * @returns {JSX.Element}                  The rendered component.
 */
export function WarnAccountModal(_props)
{
  const { t } = useTranslation();
  const accounts = useContext(AccountsContext);
  const [isWarnModalOpen, setIsWarnModalOpen] = useState(false);
  const userPreferences = useUserPref();
  const setUserPreferences = useUserPrefUpdate();
  const [, setVulnerableList] = useState([]);
  const [currentLabel, setCurrentLabel] = useState('');
  const { handleSubmit } = useForm();

  const closeModal = () => {
    setVulnerableList(list => {
      list.shift();
      if (list.length === 0)
      {
        setIsWarnModalOpen(false);
        setUserPreferences({ showWarnModalOnLogin: false });
      }
      else
      {
        setCurrentLabel(list[0]);
      }
      return list;
    });
  };

  useEffect(() =>{
    showWarnModal();

    async function showWarnModal()
    {
      const showWarnModal = userPreferences.showWarnModalOnLogin;
      const threshold = new BigNumber(500000);
      const list = [];
      for (const account of accounts)
      {
        if (account.balance.gt(threshold) && await account.testPassphrase(''))
          list.push(account.label);
      }

      if (list.length > 0 && showWarnModal)
      {
        setVulnerableList(list);
        setCurrentLabel(list[0]);
        setIsWarnModalOpen(true);
      }
    }
  }, []);

  return (
    <Modal 
      id="warnAccountModal"
      isOpen={isWarnModalOpen}
      closeModal={closeModal}
      formId={formId}
      onSubmit={handleSubmit(closeModal)}
      defaultLabel={t('close')}
      heading={t('no_password_high_credit_account_title')}
      overlayStyle={{
        zIndex: 1000,
        fontWeight: 'bold',
      }}
    >
      <p>
        <Trans
          i18nKey="no_password_high_credit_account_warning" 
          values={{label: currentLabel}}
          components={{ italic: <i />, 1: <br />, 0: <b /> }}
        />
      </p>
    </Modal>
  );
}
