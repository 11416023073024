/** @file       BankOperationsTabs.jsx
 *  @author     Alex Huctwith <alex@kingsds.network>
 *
 *  @date       April 2022
 *
 *              A component which displays an interface for buying credits.
 */
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { useTranslation } from 'react-i18next';

import { TransferTab } from './TransferTab';
import { BuyTab } from './BuyTab';
import { SellTab } from './SellTab';
import { DonateTab } from './DonateTab';

import './BankOperationTabs.css';

/**
 * Renders a component which displays an interface for buying credits.
 *
 * @param   {object}      _props Component properties.
 * @returns {JSX.Element}        The tabs component.
 */
export function BankOperationTabs(_props)
{
  const { t } = useTranslation();
  return (
    <Tabs id="bank-operations-tab">
      <TabList>
        <Tab>{t('buy')}</Tab>
        <Tab>{t('sell')}</Tab>
        <Tab>{t('transfer')}</Tab>
        <Tab>{t('donate')}</Tab>
      </TabList>
      <TabPanel>
        <BuyTab />
      </TabPanel>
      <TabPanel>
        <SellTab />
      </TabPanel>
      <TabPanel>
        <TransferTab />
      </TabPanel>
      <TabPanel>
        <DonateTab />
      </TabPanel>
    </Tabs>
  );
}
