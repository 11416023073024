/**
 * @file    CreateAccountModal.jsx
 *          Exports a modal component for creating keystores/accounts.
 *
 *          Uses `react-hook-forms` to handle form validation.
 *
 * @author  Bryan Hoang <bryan@kingsds.network>
 * @date    June 2022
 */

import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { useForm } from '@/hooks/useForm';
import { PasswordInput } from '@/components/Form';
import { TextInput } from '@/components/Form';
import { Modal } from '@/components/Elements';

import { AccountsContext, AccountsDispatchContext } from '../../stores/AccountsContext';
import { AccountOperations } from '../../api/AccountOperations';

const formId = 'createAccountForm';

/**
 * Render a create account modal component.
 *
 * @param   {object}      props            Component props.
 * @param   {boolean}     props.isOpen     Whether the modal is open.
 * @param   {function}    props.closeModal Callback to close the modal.
 * @returns {JSX.Element}                  The rendered component.
 */
export function CreateAccountModal({ isOpen, closeModal })
{
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  const { handleCreateAccount } = useContext(AccountsDispatchContext);
  const accounts = useContext(AccountsContext);
  
  // Determine the appropriate heading for the modal given the current state.
  const errorHeading = errors.label ? t('invalid_label') : '';
  const heading = errorHeading
    ? errorHeading
    : t('create_new_bank_account')

  /**
   * Validate that the password inputs match.
   *
   * @returns {true | string} The validity of the passwords or an error message.
   */
  function validatePasswordsMatch()
  {
    if (watch('password', '') !== watch('confirmPassword', ''))
    {
      return false;
    }

    return true;
  }
  
  /**
   * Validate that the account name is unique.
   *
   * @returns {true | string} The validity of the name or an error message.
   */
  function validateUniqueName(label)
  {
    let isUnique = !accounts.some((account) => account.label === label);
    if(!isUnique)
      return false;
      
    return true;
  }

  function resetAndCloseModal()
  {
    reset();
    closeModal();
  }

  async function createAccount({ label, password })
  {
    const account = await AccountOperations.createAccount(label, password);
    resetAndCloseModal();
    if (account)
      handleCreateAccount(account);
  }

  return (
    <Modal
      isOpen={isOpen}
      closeModal={resetAndCloseModal}
      heading={heading}
      isInvalid={Object.keys(errors).length > 0}
      onSubmit={handleSubmit(createAccount)}
      formId={formId}
      defaultLabel={t('create')}
      loadingLabel={t('creating')}
    >
      <TextInput 
        label={t('label')} 
        autoComplete="username" 
        autoFocus 
        {...register('label', {
          validate: validateUniqueName,
          required: t('account_name_required') 
        })} 
      />
      <PasswordInput
        label={t('password_optional')}
        autoComplete="new-password"
        {...register('password', { validate: validatePasswordsMatch })}
      />
      <PasswordInput
        label={t('confirm_password')}
        autoComplete="new-password"
        {...register('confirmPassword', { validate: validatePasswordsMatch })}
      />
      {(errors.password || errors.confirmPassword) && (
        <p className="errorMessage">{t('passwords_do_not_match')}</p>
      )}
    </Modal>
  );
}
