/**
 * @file    routes/protected.jsx - Exports a react component representing all the
 *          possible routes the web page can navigate to.
 *
 * @author  Bryan Hoang <bryan@distributive.network>
 * @date    Aug. 2023
 */

// Maintain the prevailing brace style to make it easier to parse the routes using sed.
/* eslint-disable @distributive/brace-style */

import { Outlet } from 'react-router-dom';

import { AccountsPage } from '@/features/accounts';
import { WorkerPage } from '@/features/worker';
import { UserPage } from '@/features/user';
import { PrivacyPolicyPage, TermsOfServicePage } from '@/features/misc';
import { HistoryPage } from '@/features/accounts/routes/HistoryPage';
import { Callback } from '@/features/auth/routes/Callback';


function App()
{
  return (
    <Outlet />
  );
}

export const protectedRoutes = [
  {
    path: '/',
    element: <App />,
    children: [
      { path: '/', element: <AccountsPage /> },
      { path: '/worker', element: <WorkerPage /> },
      { path: '/accounts', element: <AccountsPage /> },
      { path: '/user', element: <UserPage /> },
      { path: '/policy', element: <PrivacyPolicyPage /> },
      { path: '/tos', element: <TermsOfServicePage /> },
      { path: '/accounts/history', element: <HistoryPage /> },
      { path: '/auth/callback', element: <Callback /> },
    ],
  },
];
