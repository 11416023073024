/**
 * @file    GitRev.jsx - A React component that helps display the git hash of the built
 *          version of the Portal.
 * @author  Liang Wang <liang@distributive.network>
 * @date    August 2023
 */

import './GitRev.css';

const commit = import.meta.env.VITE_GIT_REV;

/**
 * A <p> that shows the git commit of the portal it was built against. The size is *always* 0.5em and positioned
 * on top left. It will expand to the appropriate size upon hover, and shrink it back when the hover is removed.
 *
 * @returns {JSX.Element}
 */
export function GitRev() {
  return (
    <p className={'gitHash'}>commit: {commit.replace('\n|\r\n', '')}</p>
  );
}
