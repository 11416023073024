/**
 * @file    AccountSelect.jsx - Exports a function which renders a select component for accounts.
 *
 * @author  Alex Huctwith <alex@kingsds.network>
 * @author  Bryan Hoang <bryan@kingsds.network>
 * @author  Kirill Kirnichansky <kirill@distributive.network>
 * @date    April 2022, August 2022
 */

import { forwardRef, useEffect, useState } from 'react';
import './AccountSelect.css'
import { formatBankAccountAddress, formatAccountLabel } from '../../utils/format';

/**
 * @typedef {import('@/features/accounts/stores/AccountsContext').Keystore} Keystore
 */

/**
 * Renders a component that displays a list of accounts to select from.
 *
 * @param   {object}      props             Component properties.
 * @param   {string}      props.label       The label of the input.
 * @param   {string}      props.name        The name and id of the input.
 * @param   {string}      props.placeholder Placeholder text for the input.
 * @param   {Keystore[]}  props.accounts    The list of accounts to display.
 * @param   {selected}    props.selected    Selected account within the list,
 *                                          checkmark appears beside option.
 * @param   {function}    props.onChange    Callback on select change.
 * @param   {object}      props.selectProps Rest of the props to pass to the underlying select
 *                                          element.
 * @returns {JSX.Element}                   The select component.
 */


function AccountSelectWithRef(
  { label, name, placeholder, accounts, selected, onChange, error, className = '', ...selectProps },
  ref,
) {
  const handleChange = (event) => {
    if (onChange) onChange(event);
  };

  return (
    <div className="input inlineinput">
      {label && (
        <>
          <label htmlFor={name}>{label}</label>
          <br />
        </>
      )}
      <select 
        value={selected}
        name={name} 
        id={name} 
        ref={ref} 
        onChange={handleChange}
        defaultValue={placeholder ? '' : undefined}
        className={`accountSelect font-monospace ${error ? 'invalid' : ''} ${className}`}
        {...selectProps}>
        {placeholder ? <option value="" disabled>{placeholder}</option> : <></>}
        {accounts.sort((account1, account2) => account1.label.localeCompare(account2.label)).map((account) => {return (
          <option value={account.dbKey ?? String(account.address).toLowerCase()}
            key={account.dbKey ?? String(account.address)}>
            {formatAccountLabel(account.label)}&nbsp;&nbsp;&nbsp;{formatBankAccountAddress(account.address.address || '')}
            {/* add checkmark for selected account */}
            {Number(account.dbKey) === Number(selected) && '\u00a0\u00a0\u00a0\u2713'}
          </option>
        )})}
      </select>
    </div>
  );
}

export const AccountSelect = forwardRef(AccountSelectWithRef);
