/**
 * @file    AuthContext.jsx
 * 
 *          Context for storing the idleTimer object for managing the idle timeout.
 *
 *          Contains the modal for handling an idle timeout.
 *
 * @author  David Courtis <david@distributive.network>
 * @date    Feb. 2024
 */
import { createContext, useState, useReducer } from 'react';

import { Modal } from '@/components/Elements';
import { Heading } from '@/components/Elements/Heading';
import { Button } from '@/components/Elements/Button/Button.jsx';
import { useIdleTimeout } from '@/hooks/useIdleTimeout.js';
import { LoginOperations } from '../api/LoginOperations.js';
import './AuthContext.css';

/**
 * Context that used by other components to access the `auth` state.
 */
export const AuthContext = createContext(null);

/**
 * Initial state for idleTimeout
 */
const initialIdleTimeoutState = {
  idleTimeout: Number(
    require('dcp/dcp-build').config.build === 'debug'
      ? import.meta.env.VITE_PAGE_IDLE_TIMEOUT_DEBUG
      : import.meta.env.VITE_PAGE_IDLE_TIMEOUT,
  ),
};

/**
 * Action types for idleTimeout management
 */
const idleTimeoutActionTypes = {
  SET_IDLE_TIMEOUT: 'SET_IDLE_TIMEOUT',
};

const createModalStyle = {
  width: '800px',
  top: '50%',
  left: '50%',
  right: 'auto',
  bottom: 'auto',
  marginRight: '-50%',
  transform: 'translate(-50%, -50%)',
  padding: '30px',
  maxWidth: '100%',
  boxShadow: '-4px 4px 4px rgba(0, 0, 0, 0.4)'
};

/**
 * Reducer for managing idleTimeout state
 */
function idleTimeoutReducer(state, action) 
{
  switch (action.type) 
  {
    case idleTimeoutActionTypes.SET_IDLE_TIMEOUT:
      return { ...state, idleTimeout: action.payload };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}

/**
 * Provides context to its children for accessing the `idleTimer` state.
 *
 * @param   {object}          props          Component props.
 * @param   {React.ReactNode} props.children Children of the component.
 * @returns {JSX.Element}                    The provider component.
 */
export function AuthProvider({ children })
{
  const [ openModal, setOpenModal ] = useState(false);
  const [idleTimeoutState, dispatchIdleTimeout] = useReducer(
    idleTimeoutReducer,
    initialIdleTimeoutState
  );
  const { idleTimer } = useIdleTimeout({
    onIdle: () => setOpenModal(true),
    idleTime: idleTimeoutState.idleTimeout,
  });

  const stay = () => {
    setOpenModal(false);
    idleTimer.reset();
  };
  
  const handleLogout = () => {
    LoginOperations.handleLogout();
    setOpenModal(false);
  };
  
  const setIdleTimeout = (timeout) => {
    dispatchIdleTimeout({
      type: idleTimeoutActionTypes.SET_IDLE_TIMEOUT,
      payload: timeout,
    });
  };
  
  return (
    <AuthContext.Provider value={{ idleTimer, setIdleTimeout }}>
      <Modal
        isOpen={openModal}
        onRequestClose={stay}
        contentStyle={createModalStyle}
        hideHeader={true}
        id="idleModal"
        contentLabel="Timeout Warning Modal"
        customSubmitButton={<></>}
      >
        <section>
          <Heading heading="Your session is about to expire"/>
          <div>
            <p>
              Your session is about to expire. You&apos;ll be automatically signed out.
            </p>
            <p>
              Do you want to stay signed in?
            </p>
          </div>
          <div className="idleModalButtons">
            <Button
              colour={true}
              text="Sign Out Now"
              onClick={handleLogout}
            />
            <Button
              colour={true}
              text="Stay Signed In"
              onClick={stay}
            />
          </div>
        </section>
      </Modal>
      {children}
    </AuthContext.Provider>
  );
}
