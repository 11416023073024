/**
 * @file    fetchTransactioms.js - used to fetch all the job transaction data
 *
 * @author  Kevin Yu <kevin@distributive.network>
 * @date    Apr. 2024
 */

import BigNumber from 'bignumber.js';

/**
 * Fetches all transactions for a the current user, based on their OAuth token.
 * 
 * @returns {Promise<object[]>} returns back an array with all account transactions          
 */
export async function fetchTransactions()
{
  try
  {
    const transactionRes = await fetch(
    window.dcpConfig.oAuth.location.resolve(
      '/api/v1/users/listtransactions',
    ), {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${window.oauth_token}`,
      },
    });

    if (!transactionRes?.ok) 
      return [];
    
    const transactions = await transactionRes.json();
    
    const transactionsList = [];

    // { deposit: [...], withdraw: [...] }
    // => [{ transactionType: 'deposit' | 'withdraw', ... }, ...]
    for (const [key, value] of Object.entries(transactions))
    {
      for (const transaction of value)
      {
        transaction.transactionType = key;

        // Converts string DCC currency to BigNumber 
        if(transaction.amount_credits)
          transaction.amount_credits = new BigNumber(transaction.amount_credits);
        if(transaction.amount)
          transaction.amount = new BigNumber(transaction.amount);

        transactionsList.push(transaction);
      }
    }

    return transactionsList
  } 
  catch (error) 
  {
    console.error(error);
    return [];
  }
}