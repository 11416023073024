/**
 * @file    UnlockKeystoreModal.jsx
 *          Exports a modal component for unlocking keystores/accounts.
 *
 *          Uses `react-hook-forms` to handle form validation.
 *
 * @see     {@link https://react-hook-form.com/} for further information.
 *
 * @author  Bryan Hoang <bryan@kingsds.network>
 * @date    June 2022
 */

import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useForm } from '@/hooks/useForm';
import { PasswordInput } from '@/components/Form';
import { Modal } from '@/components/Elements';

const formId = `unlockKeystoreForm`;
const inputName = 'password';

/**
 * Renders a modal to unlock a keystore.
 *
 * As a side effect of rendering, it modal overrides the `passphrasePrompt` method from the Wallet
 * API to override the default modal opened by the Wallet API.
 *
 * @param   {object}      _props Component properties.
 * @returns {JSX.Element}        The rendered modal.
 */
export function UnlockKeystoreModal(_props)
{
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [isOpen, setIsOpen] = useState(false);
  /**
   * Values to be initialized after the component has mounted in the overridden `passphrasePrompt`
   * method.
   */
  const keystoreLabel = useRef('');
  const isUnlockPasswordValid = useRef(() => {});
  const resolvePassword = useRef(() => {});
  const rejectPassword = useRef(() => {});

  const closeModal = useCallback(() => {
    reset();
    setIsOpen(false);
  }, [reset]);

  /**
   * Overrides the default `passphrasePrompt` the Wallet API uses when a keystore is being unlocked.
   *
   * When `keystore.unlock()` is called, this function will eventually be called, opening the modal.
   * Then when the form is submitted or the modal is closed, the promise will be resolved or
   * rejected respectively.
   *
   * See the `passphrasePrompt` and `passphraseTries` functions in
   * `src/dcp-client/wallet/passphrase-prompt.js` for more details on the overridden function.
   *
   * @param   {object}          metaData                 The keystore's `label`, `address`, and
   *                                                     `maxTries` until giving up.
   * @param   {function}        tryAndRememberPassphrase Determines if the password is valid.
   * @returns {Promise<string>}                          The password that unlocks the keystore.
   */
  const overridePassphrasePrompt = useCallback(
    ({ label }, tryAndRememberPassphrase) => {
      return new Promise((resolve, reject) => {
        // Capturing references to pass to the unlock modal before opening it.
        keystoreLabel.current = label;
        isUnlockPasswordValid.current = tryAndRememberPassphrase;
        resolvePassword.current = (password) => {
          closeModal();
          resolve(password);
        };
        rejectPassword.current = () => {
          closeModal();
          reject(new Error('Cancelled unlocking account.'));
        };
        setIsOpen(true);
      });
    },
    [closeModal],
  );

  useEffect(() => {
    window.wallet.passphrasePrompt = overridePassphrasePrompt;
    return () => {};
  }, [overridePassphrasePrompt]);

  /**
   * Submit the form knowing that the password has already been validated.
   * Resolves the promise that overrides `wallet.passphrasePrompt`
   *
   * @param {string} password
   */
  const resetFormBeforeSubmitting = (password) => {
    reset();
    resolvePassword.current(password);
  };

  /**
   * Validate the keystore password.
   *
   * Attempts to unlock the keystore with the given password, otherwise displays an error.
   *
   * @param   {string}                 password The password to validate.
   * @returns {Promise<true | string>}          The validity of the pasword or the error message.
   */
  async function validateKeystorePassword(password)
  {
    if (await isUnlockPasswordValid.current(password))
    {
      return true;
    }

    return false;
  }

  return (
    <Modal
      isOpen={isOpen}
      closeModal={rejectPassword.current}
      heading={t('unlock_keystore')}
      subHeading={keystoreLabel.current}
      isInvalid={Object.keys(errors).length > 0}
      formId={formId}
      onSubmit={handleSubmit(resetFormBeforeSubmitting)}
      defaultLabel={t('unlock')}
      loadingLabel={t('unlocking')}
    >
      <PasswordInput
        label={t('password')}
        autoComplete="password"
        autoFocus
        {...register(inputName, {
          validate: validateKeystorePassword,
        })}
      />
      {errors.password && <p className="errorMessage">{t('incorrect_password')}</p>}
    </Modal>
  );
}
