/**
 *  @file       widgets/accounts-list/account/account.jsx
 *  @author     Alex Huctwith <alex@kingsds.network>
 *  @author     Kevin Yu <kevin@distributive.network>
 *
 *  @date       April 2022
 *
 *              A component for displaying an account's listing as part of the accounts list
 *              References an element with an id of the account's address which is the more
 *              pane for that component
 *              Props:
 *                - account: the keystore object for the account
 *                  - account.balance: the keystore object should have either a balance value or something falsey if locked
 */

import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { CopyToClipboard } from '@/components/Elements';
import { AccountDropdown } from './AccountDropdown';
import { DCC } from '../../DCC';
import {
  ChangeAccountPasswordModal,
  DeleteAccountModal,
  DownloadKeystoreModal,
  RenameAccountModal,
} from '../../Modals';

import './Account.css';
import { useNavigate } from 'react-router-dom';
import { formatBankAccountAddress } from '@/features/accounts/utils/format';
import { Tooltip } from '@/components/Elements/Tooltip/Tooltip';
import { useToast } from '@/components/Elements/Toast/useToast';

/**
 * @typedef {import('@/features/accounts/stores/AccountsContext').Account} Account
 */
/**
 * Renders a component for displaying an account's listing as part of the accounts list.
 *
 * @param   {object}      props         Component properties.
 * @param   {Account}     props.account The account to display information for.
 * @returns {JSX.Element}               The table row component.
 */
export function Account({ account })
{
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const copyRef = useRef(null);
  const navigate = useNavigate();
  const { toast } = useToast();
  const { t } = useTranslation('accounts');

  useEffect(() => {
    if (account.unlocked)
      return;
    if (!(isRenameModalOpen || isChangePasswordModalOpen || isDownloadModalOpen))
      return;

    toast({
      title: t('accounts.toast.unlock_expire_title', { ns: 'validation', label: account.label }),
      description: t('accounts.toast.unlock_expire_description', { ns: 'validation' }),
      duration: 5000,
    });
    closeAllModals();

    function closeAllModals()
    {
      setIsRenameModalOpen(false);
      setIsChangePasswordModalOpen(false);
      setIsDownloadModalOpen(false);
    }
  }, [
    account.label,
    account.unlocked,
    isChangePasswordModalOpen,
    isDownloadModalOpen,
    isRenameModalOpen,
    t,
    toast,
  ]);

  const handleNavigateToTransactions = () => {
    if (!account.unlocked)
      return;
    navigate(`/accounts/history?account=${account.label}`);
  };

  return (
    <tr
      id={account.address}
      className={`accountEntry${account.unlocked ? '' : ' locked'}`}
    >
      {/* Account */}
      <td className="account-label">
        <Tooltip
          side="bottom"
          content={account.label}
          asChild
        >
          <button onClick={handleNavigateToTransactions} data-reset>{account.label}</button>
        </Tooltip>
      </td>

      {/* Account Number */}
      <td className="accountNumber">
        <div
          onClick={(event) => copyRef.current.handleClickToCopy(event)}
          role="button"
        >
          <button 
            type="button"
            className="text" 
            title={account.address} 
            onClick={(event) => copyRef.current.handleClickToCopy(event)}
            data-reset
          >
            {formatBankAccountAddress(account.address)}
          </button>
          <CopyToClipboard
            copyText={String(account.address).toLowerCase()}
            ref={copyRef}
            className="xs-hidden"
            toastMessage={t('copied_account_number', { ns: 'app' })}
          />
        </div>
      </td>

      {/* Credits */}
      <td className="balance">
        <DCC balance={account.balance} />
      </td>

      {/* Menu Icon/Locked Icon */}
      <td>
        <AccountDropdown
          className="dropdownMenu"
          account={account}
          openRenameModal={() => setIsRenameModalOpen(true)}
          openChangeAccountPasswordModal={() => setIsChangePasswordModalOpen(true)}
          openDeleteModal={() => setIsDeleteModalOpen(true)}
          openDownloadModal={() => setIsDownloadModalOpen(true)}
          isUnlocked={account.unlocked}
          handleNavigateToTransactions={handleNavigateToTransactions}
        />
      </td>
      <RenameAccountModal
        isOpen={isRenameModalOpen}
        closeModal={() => setIsRenameModalOpen(false)}
        account={account}
      />
      <ChangeAccountPasswordModal
        isOpen={isChangePasswordModalOpen}
        closeModal={() => setIsChangePasswordModalOpen(false)}
        account={account}
      />
      <DeleteAccountModal
        isOpen={isDeleteModalOpen}
        closeModal={() => setIsDeleteModalOpen(false)}
        account={account}
      />
      <DownloadKeystoreModal
        isOpen={isDownloadModalOpen}
        closeModal={() => setIsDownloadModalOpen(false)}
        account={account}
      />
    </tr>
  );
}
