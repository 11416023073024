/** @file       pages/workerpage.jsx
 *  @author     Alex Huctwith <alex@kingsds.network>
 *
 *  @date       April 2022
 *
 *              Portal page for the portal worker
 */

import { useTranslation } from 'react-i18next';

import { Layout } from '@/components/Layout';
import { WorkerPanel } from '../components/WorkerPanel';

export function WorkerPage() {
  const { t } = useTranslation();

  return(
    <Layout mobileHeader={t('worker')}>
      <WorkerPanel />
    </Layout>
  );
}
